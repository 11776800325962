import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleTagManagerService } from '@core/index';
import {
  HOME_GTM_SEGMENTS,
  HomeAnalytics,
  HomeGtmSegments,
} from '@models/analytics';
import { ProfileService, SegmentService } from '@shared/services';
import { ISegment } from '@modules/home/models/home';
import { PictureComponent } from '../picture/picture.component';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'qsc-segment-card',
  templateUrl: './segment-card.component.html',
  styleUrls: ['./segment-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    PictureComponent,
    ButtonComponent,
    TranslateModule
],
})
export class SegmentCardComponent implements OnInit {
  @Input() data?: ISegment;
  @Input() index?: number;
  @Input() withCta = true;
  @Input() isHome = false;
  @Input() isSwimmingPoolFlow = false;
  translatedDescription = '';
  imageDataKey: keyof ISegment['imageUrl'] = 'home';

  constructor(
    private readonly googleTagManager: GoogleTagManagerService,
    private readonly router: Router,
    private readonly segmentService: SegmentService,
    private readonly profileService: ProfileService,
  ) {}

  ngOnInit(): void {
    this.handleImagesDataKey();
  }

  private handleImagesDataKey(): void {
    if (this.isHome) {
      this.imageDataKey = 'home';
      return;
    }
    if (this.isSwimmingPoolFlow) {
      this.imageDataKey = 'pools';
      return;
    }
    this.imageDataKey = 'default';
  }

  handleCta(segment: ISegment) {
    this.sendGtmEventOnHandleCta(segment.id);
    this.segmentService.select(segment.id);
    this.profileService.selectDefault(segment.id);
    this.router.navigateByUrl(segment.link);
  }

  sendGtmEventOnHandleCta(segmentId: keyof HomeGtmSegments) {
    if (!segmentId) return;
    this.googleTagManager.sendCustomEvent(
      HomeAnalytics.category.tipoDeImovel,
      HOME_GTM_SEGMENTS[segmentId],
      HomeAnalytics.label.clique
    );
  }

  sendGtmEventOnKnowMore(segmentId: keyof HomeGtmSegments): void {
    if (!segmentId) return;
    this.googleTagManager.sendCustomEvent(
      HomeAnalytics.category.tipoDeImovel,
      HOME_GTM_SEGMENTS[segmentId],
      HomeAnalytics.label.saberMais
    );
  }

  isAddTagBr(description: string): boolean {
    return !!(description && description.length <= 120);
  }
}
