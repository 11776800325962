@if (data) {
  <div class="colored-card" [ngClass]="modifierClass">
    <div class="mh-100">
      <div class="colored-card__img-container">
        <qsc-picture
        [png]="{
          mobile: data.image.mobile?.png,
          desktop: data.image.desktop?.png
        }"
        [webp]="{
          mobile: data.image.mobile?.webp,
          desktop: data.image.desktop?.webp
        }"
          [breakpoints]="data.image.breakpoints"
          [alt]="data.image.alt"
          [title]="data.image.title"
          [fullWidth]="true"
          [fullHeight]="true"
        ></qsc-picture>
      </div>
      <div>
        <h3 class="colored-card__title" [innerHTML]="data.title | translate"></h3>
        <div
          class="colored-card__description"
        [ngClass]="{
          'read-more-text': showReadMore,
          'overflow-y-scroll': !showReadMore
        }"
          [innerHTML]="data.description | translate"
          #description
        ></div>
      </div>
    </div>
    <button class="read-more-button pt-2" (click)="toggleReadMore()">
      {{ (showReadMore ? "@CONTINUE-READING" : "@READ-LESS") | translate }}
    </button>
  </div>
}
