import { Injectable } from '@angular/core';
import { IMarkerData } from './marker/marker-data';
import { MarkerTypeEnum } from './marker/marker-type.enum';
import { GoogleMapsFacade } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  data: IMarkerData[] = [];
  map?: google.maps.Map;
  origin = '';
  destination = '';
  originDestinationWasAdded = false;
  areaWasAdded = false;
  latLngOrigin!: google.maps.LatLngLiteral;
  private placeResultCache: {
    [key: string]: google.maps.places.PlaceResult;
  } = {};
  idleListener?: google.maps.MapsEventListener;

  constructor(private readonly googleMapsFacade: GoogleMapsFacade) { }

  async initMapAsync(elementId: string): Promise<void> {
    await this.googleMapsFacade.createMapAsync(elementId).then(async (map) => {
      this.map = map;

      await this.googleMapsFacade.initializeDirectionsService();
      await this.googleMapsFacade.initializeDirectionsRendererService(this.map);

      this.googleMapsFacade.waypointService.waypoints$.subscribe(async () => {
        if (this.origin && this.destination) {
          await this.createRoute().then(() => {
            this.googleMapsFacade.addWaypointContainer(this.data);
          });
        }
      });

      this.originDestinationWasAdded = false;
      this.areaWasAdded = false;
    });
  }

  async createRoute(): Promise<google.maps.DirectionsResult | null> {
    return new Promise((resolve, reject) => {
      this.googleMapsFacade
        .createRoute(
          this.origin,
          this.destination,
          this.googleMapsFacade.getWaypoints()
        )
        .then(async (directionsResult) => {
          if (!directionsResult) {
            reject(new Error('Failed to create route'));
            return;
          }

          const optimizedWaypoints = this.getOptimizedWaypoints(
            directionsResult?.routes[0]?.waypoint_order ?? []
          );

          this.googleMapsFacade.setOptimizedWaypoints(optimizedWaypoints);

          const route = directionsResult.routes[0].legs[0];

          if (!this.originDestinationWasAdded) {
            this.addMarker(this.origin, route.start_location);
            this.addMarker(this.destination, route.end_location);
            this.originDestinationWasAdded = true;
          }

          this.googleMapsFacade.renderRoute(directionsResult);

          resolve(directionsResult);
        })
        .catch(() => {
          reject(new Error('Failed to create route'));
        });
    });
  }

  clearRoute(): void {
    this.googleMapsFacade.clearRoute();
  }

  private drawCircle(distance?: number): void {
    if (this.map && this.latLngOrigin) {
      this.googleMapsFacade.drawCircle(this.map, this.latLngOrigin, distance);
    }
  }

  private filterLocations(distance?: number): void {
    if (this.data.length && this.latLngOrigin) {
      this.data = this.googleMapsFacade.filterLocations(
        this.data,
        this.latLngOrigin,
        distance
      );
    }
  }

  async addMarker(name: string, latLng?: google.maps.LatLng): Promise<void> {
    const marker = await this.googleMapsFacade.createMarkerAsync(
      MarkerTypeEnum.Default,
      {
        latitude: latLng?.lat(),
        longitude: latLng?.lng(),
        name,
      },
      this.map
    );

    this.googleMapsFacade.addMarker(marker);
  }

  async clearAll() {
    this.originDestinationWasAdded = false;
    this.areaWasAdded = false;

    this.googleMapsFacade.clearMarkers();
    this.googleMapsFacade.removeActionButtons();
    this.googleMapsFacade.removeLegends();
    this.clearShapes();
    this.clearRoute();
  }

  async updateMarkersToRoute(
    origin?: string,
    destination?: string,
    showOpenedStationsOnly?: boolean,
    showHighFlowOnly?: boolean
  ): Promise<void> {
    if (!this.map) return;

    this.origin = origin ?? '';
    this.destination = destination ?? '';

    this.clearAll();

    this.originDestinationWasAdded = false;

    if (this.origin && this.destination) {
      this.map?.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(
        this.googleMapsFacade.createLegendForRoute()
      );
    } else {
      this.googleMapsFacade.removeLegends();
    }

    if (this.data.length > 0) {
      const markers = await Promise.all(
        this.data
          .filter((f) => f.installation)
          .map(async (data: IMarkerData) => {
            const markerType = data.highFlow
              ? MarkerTypeEnum.HighFlow
              : MarkerTypeEnum.Common;

            const marker = await this.googleMapsFacade.createMarkerAsync(
              markerType,
              data,
              this.map
            );

            this.setVisibility(
              marker,
              data,
              Boolean(showHighFlowOnly),
              Boolean(showOpenedStationsOnly)
            );

            if (origin && destination && (marker as any).data.visible) {
              this.googleMapsFacade.createActionButtons(
                marker,
                data,
                this.map!
              );
            }

            return marker;
          })
      );

      this.googleMapsFacade.setMarkers(markers);
    }
  }

  async updateMarkersToSearchArea(
    origin?: string,
    distance?: number,
    showOpenedStationsOnly?: boolean,
    showHighFlowOnly?: boolean,
    showOpenedStationsOnlyChanged?: boolean
  ) {
    if (!this.map) return;

    this.origin = origin ?? '';

    this.clearAll();

    await this.handleQueryLatLng(this.origin);

    this.map?.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(
      this.googleMapsFacade.createLegendForSearchArea()
    );

    this.filterLocations(distance);
    this.drawCircle(distance);

    if (this.data.length > 0) {
      const markers = await Promise.all(
        this.data
          .filter((f) => f.installation)
          .map(async (data: IMarkerData) => {
            const markerType = data.highFlow
              ? MarkerTypeEnum.HighFlow
              : MarkerTypeEnum.Common;

            const marker = await this.googleMapsFacade.createMarkerAsync(
              markerType,
              data,
              this.map
            );

            if (showOpenedStationsOnlyChanged) {
              this.updateAvailabilityData(marker, this.map!).then(() => {
                this.setVisibility(
                  marker,
                  data,
                  showHighFlowOnly!,
                  showOpenedStationsOnly!
                );
              });
            } else {
              this.setVisibility(
                marker,
                data,
                showHighFlowOnly!,
                showOpenedStationsOnly!
              );
            }

            return marker;
          })
      );

      this.googleMapsFacade.setMarkers(markers);
    }

    if (!this.areaWasAdded) {
      this.addMarker(this.origin, new google.maps.LatLng(this.latLngOrigin));
      this.areaWasAdded = true;
    }
  }

  setVisibility(
    marker: google.maps.marker.AdvancedMarkerElement,
    data: IMarkerData,
    showHighFlowOnly: boolean,
    showOpenedStationsOnly: boolean
  ) {
    const visible = this.isVisible(
      marker,
      showHighFlowOnly,
      showOpenedStationsOnly
    );
    (marker as any).data.visible = visible;
    data.visible = visible;
    marker.map = visible ? this.map : null;
    marker.hidden = !visible;
  }

  isVisible(
    marker: google.maps.marker.AdvancedMarkerElement,
    showHighFlowOnly: boolean,
    showOpenedStationsOnly: boolean
  ): boolean {
    if (showHighFlowOnly && !(marker as any).data.highFlow) {
      return false;
    }
    if (showOpenedStationsOnly && !(marker as any).data.isOpen) {
      return false;
    }
    return true;
  }

  private isMarkerVisible(
    marker: google.maps.marker.AdvancedMarkerElement,
    map: google.maps.Map
  ): boolean {
    const bounds = map.getBounds();
    if (!bounds) return false;

    const position = marker.position;
    return position ? bounds.contains(position) : false;
  }

  async updateAvailabilityData(
    marker: google.maps.marker.AdvancedMarkerElement,
    map: google.maps.Map
  ): Promise<void> {
    if (!(marker as any).data?.installation || marker.hidden) {
      return;
    }

    if (this.isMarkerVisible(marker, map)) {
      await this.googleMapsFacade.fetchOpeningHours((marker as any).data);
    }
  }

  async handleQueryLatLng(query: string) {
    const cachedPlace = this.placeResultCache[query];
    const result =
      cachedPlace ?? (await this.googleMapsFacade.searchPlace(query))[0];

    if (result?.geometry?.location) {
      const latLng = {
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
      };

      this.latLngOrigin = latLng;

      if (!cachedPlace) {
        this.placeResultCache[query] = result;
      }
    }
  }

  clearShapes(): void {
    this.googleMapsFacade.removeDistanceCircle();
  }

  clearWaypoints(): void {
    this.googleMapsFacade.clearWaypoints();
    this.googleMapsFacade.clearWaypointsInputs();
  }

  startNavigation(): void {
    this.googleMapsFacade.startNavigation(
      this.origin,
      this.destination,
      this.googleMapsFacade.getOptimizedWaypoints()
    );
  }

  centerMap(): void {
    if (!this.googleMapsFacade.getMarkers().length || !this.map) return;

    const bounds = new google.maps.LatLngBounds();
    this.googleMapsFacade
      .getMarkers()
      .forEach((marker) => bounds.extend(marker.position!));

    this.map.fitBounds(bounds);
    this.setMaxZoom(this.map);
  }

  private getOptimizedWaypoints(
    waypointOrder: number[]
  ): google.maps.DirectionsWaypoint[] {
    return waypointOrder.map(
      (index) => this.googleMapsFacade.getWaypoints()[index]
    );
  }

  private setMaxZoom(map: google.maps.Map): void {
    const defaultZoom = 15;
    const zoomMap = map.getZoom() ?? defaultZoom;
    const maxZoom =
      this.googleMapsFacade.mapSettings.defaultOptions().maxZoom ?? defaultZoom;

    if (zoomMap > maxZoom) {
      map.setZoom(maxZoom);
    }
  }

  async removeListenerToMarker() {
    google.maps.event.removeListener(this.idleListener!);
  }
}
