<div class="home-segment-card home-segment-card--mobile d-lg-none">
  <div class="home-segment-card__title">
    <span>
      <i aria-hidden="true" [class]="data.iconClass"></i>
    </span>
    <h3 [innerHTML]="data.title | translate"></h3>
  </div>
  <div class="home-segment-card__description">
    <p [innerHTML]="(data.description | translate) + ' '"></p>
    @if (isAddTagBr(data.description | translate)) {
    <br />
    }
    <a
      (click)="sendGtmEventOnKnowMore()"
      [routerLink]="data.linkContent"
      class="home-segment-card__know-more"
      [innerHTML]="'@KNOW-MORE' | translate"
    ></a>
  </div>
  <qsc-button
    (buttonClick)="handleCta()"
    [variant]="data.buttonVariant || 'green'"
    [buttonText]="data.buttonText | translate"
  ></qsc-button>
</div>

<div
  class="home-segment-card home-segment-card--desktop d-none d-lg-block"
  tabindex="0"
>
  <div class="home-segment-card__background">
    <qsc-picture
      [png]="{
        mobile: data.imageUrl.home.mobile?.png,
        desktop: data.imageUrl.home.desktop?.png
      }"
      [webp]="{
        mobile: data.imageUrl.home.mobile?.webp,
        desktop: data.imageUrl.home.desktop?.webp
      }"
      [alt]="data.imageUrl.home.alt"
      [fullWidth]="true"
    ></qsc-picture>
  </div>
  <div class="home-segment-card__overlay">
    <div class="home-segment-card__overlay-content">
      <div>
        <p
          class="home-segment-card__overlay-title"
          [innerHTML]="data.title | translate"
        ></p>
        <div class="home-segment-card__overlay-description">
          <p [innerHTML]="(data.description | translate) + ' '"></p>
          @if (isAddTagBr(data.description | translate, 105)) {
          <br />
          }
          <a
            (click)="sendGtmEventOnKnowMore()"
            [routerLink]="data.linkContent"
            class="home-segment-card__know-more"
            [innerHTML]="'@KNOW-MORE' | translate"
          ></a>
        </div>
      </div>
      <qsc-button
        (buttonClick)="handleCta()"
        [variant]="data.buttonVariant || 'green'"
        [buttonText]="data.buttonText | translate"
      ></qsc-button>
    </div>
  </div>
  <div class="home-segment-card__title-box">
    <h3 [innerHTML]="data.title | translate"></h3>
  </div>
</div>
