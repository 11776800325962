import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { CategoryIdsEnum, ProfileIdsEnum } from '@models/enums';
import { Observable, distinctUntilChanged, filter, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { fromUser } from '@core/store';
import { CategoryService } from '@shared/services/category/category.service';
import { ProfileService } from '@shared/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'qsc-category',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CategoryComponent implements OnInit {
  selectedCategory$!: Observable<CategoryIdsEnum | undefined>;
  selectedCategoryId?: number;

  constructor(
    private readonly store: Store,
    protected readonly profileService: ProfileService,
    protected readonly categoryService: CategoryService
  ) {}

  protected get profileIdsEnum() {
    return ProfileIdsEnum;
  }

  protected get categoryIdsEnum() {
    return CategoryIdsEnum;
  }

  ngOnInit(): void {
    this.selectedCategory$ = this.store.select(fromUser.selectUserData).pipe(
      distinctUntilChanged(),
      filter((categoryId) => categoryId !== null),
      map((data) => data?.categoryId)
    );

    this.selectedCategory$
      .pipe(untilDestroyed(this))
      .subscribe((categoryId: CategoryIdsEnum | undefined) => {
        this.selectedCategoryId = categoryId;
      });
  }
}
