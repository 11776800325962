import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'qsc-input',
  templateUrl: './input.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormFieldModule,
    NgClass,
    SvgIconComponent,
    MatInputModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatIconModule,
    TranslateModule
],
  providers: [provideNgxMask()],
})
export class InputComponent {
  @Input() control: FormControl = new FormControl();
  @Input() format = '';
  @Input() pattern = '';
  @Input() outsideLabel = '';
  @Input() insideLabel = '';
  @Input() id = '';
  @Input() name = '';
  @Input() type = 'text';
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() required = false;
  @Input() success = true;
  @Input() loading = false;
  @Input() confirmed = false;
  @Input() iconClass = '';
  @Input() invalidErrorIconSvg = '';
  @Input() invalidErrorMessage = '';
  @Input() errorMatcher = new ErrorStateMatcher();
  @Input() dataTestId = '';
  @Input() tabIndex = 0;
  @Input() prefixIcon: { enable: boolean; iconClass?: ''; iconSvg?: '' } = {
    enable: true,
  };
  @Input() showCheckIcon = true;

  @Output() keyUp = new EventEmitter();

  @ViewChild('input')
  public input: ElementRef | undefined;

  optionalControls: any[] = ['optional-complement'];

  isValid(): boolean {
    if (!this.optionalControls.find((x) => x === this.name)) {
      return this.control.valid;
    }
    if (this.control.value) return true;
    return false;
  }
}
