<div class="segment-card">
  @if (data) {
    <div class="d-flex flex-column justify-content-between h-100">
      <div>
        <div
          class="segment-card__image-bg"
        [ngClass]="{
          'cropped-image': index === 0 && withCta && !isHome
        }"
          >
          <div class="segment-card__degrade"></div>
          <qsc-picture
          [webp]="{
            desktop: data.imageUrl[imageDataKey]?.desktop?.webp,
            mobile: data.imageUrl[imageDataKey]?.mobile?.webp
          }"
          [png]="{
            desktop: data.imageUrl[imageDataKey]?.desktop?.png,
            mobile: data.imageUrl[imageDataKey]?.mobile?.png
          }"
            [alt]="data.imageUrl[imageDataKey]?.alt ?? '' | translate"
            >
          </qsc-picture>
          @if (!isHome && !isSwimmingPoolFlow) {
            <div
              class="segment-card__image-title"
              >
              <span>
                <i aria-hidden="true" [class]="data.iconClass"></i>
              </span>
              <p [innerHTML]="data.title | translate"></p>
            </div>
          }
        </div>
        @if (isHome) {
          <div class="segment-card__title">
            <span>
              <i aria-hidden="true" [class]="data.iconClass"></i>
            </span>
            <p [innerHTML]="data.title | translate"></p>
          </div>
        }
        @if (isSwimmingPoolFlow) {
          <p
            class="segment-card__description-title mx-3"
            >
            {{ "@HOME-SEGMENTOS-CARD-DESCRIPTION-TITLE" | translate }}
          </p>
        }
        <p class="segment-card__description mx-3">
          @if (!isSwimmingPoolFlow) {
            <span
              [innerHTML]="data.description | translate"
            ></span>
          }
          @if (isSwimmingPoolFlow) {
            <span
              [innerHTML]="data.titleAlt || data.title | translate"
            ></span>
          }
          @if (isHome && isAddTagBr(data.description | translate)) {
            <br />
          }
          @if (isHome) {
            <a
              (click)="sendGtmEventOnKnowMore(data.id)"
              [routerLink]="data.linkContent"
              class="segment-card__know-more"
              [innerHTML]="'@KNOW-MORE' | translate"
            ></a>
          }
        </p>
      </div>
      @if (isHome || withCta) {
        <div class="px-3">
          <qsc-button
            (buttonClick)="handleCta(data)"
            variant="green"
            [buttonText]="data.buttonText | translate"
          ></qsc-button>
        </div>
      }
    </div>
  }
</div>
