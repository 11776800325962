import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ChatService } from '@shared/services';

@Component({
  selector: 'qsc-chat-message',
  standalone: true,
  imports: [AsyncPipe, TranslateModule],
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageComponent implements OnInit {
  showChatMessage$!: Observable<boolean>;

  constructor(private readonly chatService: ChatService) {}

  ngOnInit(): void {
    this.showChatMessage$ = this.chatService.getChatMessageStatus();
  }

  hideChatMessage(): void {
    this.chatService.hideChat();
  }
}
