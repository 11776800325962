@if (image) {
  <div class="first-banner">
    <div class="first-banner__background">
      <div class="first-banner__img-background">
        <qsc-picture
          [breakpoints]="image.breakpoints"
          [alt]="image.alt"
          [title]="image.title"
          [fullWidth]="true"
          [fullHeight]="true"
          [lazy]="false"
        ></qsc-picture>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
}
