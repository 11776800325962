import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgClass, TitleCasePipe } from '@angular/common';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FlowsAnalytics } from '@models/analytics';
import { FlowService } from '@shared/services';
import { ButtonComponent, SvgIconComponent } from '@shared/components';

@Component({
  selector: 'qsc-address-confirmation-modal',
  templateUrl: './address-confirmation-modal.component.html',
  styleUrls: ['./address-confirmation-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    SvgIconComponent,
    NgClass,
    MatCheckboxModule,
    ReactiveFormsModule,
    ButtonComponent,
    TitleCasePipe,
    TranslateModule
],
})
export class AddressConfirmationModalComponent implements OnInit {
  street?: string;
  neighborhood?: string;
  number?: string;
  complement?: string;
  apartment?: string;
  confirmAddress = false;

  addressConfirmationForm = this.fb.group({
    address: [false, [Validators.requiredTrue]],
    apartment: [false],
    complement: [false],
  });

  constructor(
    public bsModalRef: BsModalRef,
    private readonly fb: FormBuilder,
    private readonly flowService: FlowService
  ) {}

  ngOnInit(): void {
    this.sendGtmEventOnOpenModal();
    if (this.apartment) {
      this.addressConfirmationForm.controls.apartment.setValidators(
        Validators.requiredTrue
      );
    }
    if (this.complement) {
      this.addressConfirmationForm.controls.complement.setValidators(
        Validators.requiredTrue
      );
    }
  }

  onConfirmAddress(): void {
    this.confirmAddress = true;
    this.bsModalRef.hide();
  }

  onChangeAddress(): void {
    this.confirmAddress = false;
    this.bsModalRef.hide();
  }

  sendGtmEventOnOpenModal(): void {
    this.flowService.sendGtmEventWithFlow(
      FlowsAnalytics.category.confirmacaoEndereco,
      FlowsAnalytics.action.visualizacao,
      ''
    );
  }
}
