import { fromUser } from '@core/index';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ProfileService, TmeoService } from '@shared/services';
import {
  IDisponibilidadeRedeInput,
  getCategory,
  getProfile,
} from '@models/index';
import { EquipmentService } from '@modules/equipment-selection/services/equipment/equipment.service';
import { PersonalDataMapper, AddressMapper } from '@shared/mappers/index';
import { ViabilityStudyEnum } from '@models/enums/viability-study.enum';
import { CategoryService } from '@shared/services/category/category.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkAvailabilityMapper {
  userData?: fromUser.IUser | null;

  constructor(
    private readonly store: Store,
    private readonly profileService: ProfileService,
    private readonly equipmentService: EquipmentService,
    private readonly tmeoService: TmeoService,
    private readonly categoryService: CategoryService,
    private readonly personalDataMapper: PersonalDataMapper,
    private readonly addressMapper: AddressMapper
  ) {
    this.store
      .select(fromUser.selectUserData)
      .subscribe((data) => {
        this.userData = data;
      });
  }

  getViabilityStudy(): string {
    if (this.tmeoService.isTmeo()) return ViabilityStudyEnum.Viable;
    if (!this.userData?.concession) return ViabilityStudyEnum.Impracticable;
    return ViabilityStudyEnum.NotApplicable;
  }

  isBuilding = (): boolean => this.profileService.isBuildings();

  createNetworkAvailabilityInput(): IDisponibilidadeRedeInput {
    return {
      dadosPessoais: this.personalDataMapper.createPersonalData(),
      dadosEndereco: {
        ...this.addressMapper.createAddressData(),
        altopadrao: this.userData?.address?.altopadrao,
        situacaoCondo: this.isBuilding()
          ? getCategory(this.categoryService)
          : undefined,
      },
      dadosGeogas: {
        concessao: this.userData?.concession,
        ramal: this.userData?.extension,
        rede: this.userData?.network,
        latitude: this.userData?.latitude as number,
        longitude: this.userData?.longitude as number,
        estudoviabilidade: this.getViabilityStudy(),
      },
      equipamentos:
        this.equipmentService.loadAndFormatEquipmentsToNetworkAvailability() ??
        [],
      perfil: getProfile(this.profileService),
    };
  }
}
