import { Injectable } from '@angular/core';
import { IMarkerData } from '../marker/marker-data';
import { TranslateService } from '@ngx-translate/core';
import { OpeningHoursService } from '../opening-hours/opening-hours.service';

@Injectable({
  providedIn: 'root',
})
export class InfoWindowService {
  private readonly linkBase = 'https://www.google.com/maps';

  content: HTMLElement | null = null;

  constructor(
    private readonly translateService: TranslateService,
    private readonly openingHours: OpeningHoursService
  ) { }

  async createContent(data: IMarkerData): Promise<HTMLElement> {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          this.content = document.createElement('div');
          this.content.classList.add('info-window-content');

          const container = document.createElement('div');
          container.append(
            this.createTitle(data.name!),
            this.createAddress(data),
            await this.openingHours.createOpeningHours(data),
            this.createComgasLabel(),
            this.createMapLink(data),
          );

          this.content.append(container);

          resolve(this.content);
        } catch (error) {
          reject(error);
        }
      })();
    });
  }

  private createTitle(title: string): HTMLElement {
    const element = document.createElement('div');
    element.classList.add('info-window-content__name');
    element.appendChild(document.createTextNode(title));
    return element;
  }

  private createAddress(data: IMarkerData): HTMLElement {
    const address = document.createElement('div');
    address.classList.add('info-window-content__address');

    if (data.address) {
      address.append(
        document.createTextNode(`${data.address} -`),
        document.createTextNode(`${data.area}, ${data.city} - ${data.state}, `),
        document.createTextNode(data.postalCode ?? '')
      );
    }

    return address;
  }

  private createMapLink(data: IMarkerData): HTMLElement {
    const container = document.createElement('div');
    container.classList.add('info-window-content__link');

    const href = data.address ? data.link : `${this.linkBase}/place/${data.name}`;

    const link = document.createElement('a');
    link.target = '_blank';
    link.href = href!;
    link.textContent = this.translateService.instant('@VEHICLES-CONTENT-MAPS-LABEL-1');

    container.append(link);

    return container;
  }

  private createComgasLabel(): HTMLElement {
    const container = document.createElement('div');
    container.classList.add('info-window-content__comgas');

    const icon = document.createElement('i');
    icon.classList.add('icon-chama_comgas');

    const label = document.createElement('span');
    label.textContent = this.translateService.instant('@VEHICLES-CONTENT-MAPS-INFO-WINDOW-LABEL-5');

    container.append(icon, label);

    return container;
  }
}
