<div class="flow-title d-flex flex-column gap-3">
  <div class="flow-title__container">
    <div
      class="flow-title__icon"
      [ngClass]="{
        'flow-title__icon--red': iconColor === 'red',
        'flow-title__icon--green': iconColor === 'green',
        'flow-title__icon--blue': iconColor === 'blue',
        'flow-title__icon--fire-bg': fireBackground
      }"
      >
      @if (iconClass) {
        <i aria-hidden="true" [class]="iconClass"></i>
      }
      @if (iconImg) {
        <img [src]="iconImg | fileHash" alt="" />
      }
    </div>
    <h1 class="flow-title__title">{{ title }}</h1>
  </div>
  @if (subtitle) {
    <p class="flow-title__subtitle" [innerHTML]="subtitle"></p>
  }
</div>
