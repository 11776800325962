import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'qsc-heading',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadingComponent {
  @Input() title = '';
  @Input() subtitle = '';
}
