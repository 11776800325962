<div class="card-colored-border">
  <div class="card-colored-border__container-top">
    <div class="card-colored-border__bg-gradient"></div>
    <div class="card-colored-border__bg-image">
      @if (image) {
        <qsc-picture
          [breakpoints]="image.breakpoints"
          [alt]="image.alt | translate"
          [title]="image.title ?? '' | translate"
          [fullWidth]="true"
          [fullHeight]="true"
        ></qsc-picture>
      }
    </div>
    <h3 class="card-colored-border__title" [innerHTML]="title | translate"></h3>
  </div>
</div>
