<div class="container">
  <div class="d-flex">
    <div class="container__cris">
      <img [src]="'assets/img/svg/cris.svg' | fileHash" alt="" loading="lazy" />
    </div>
    <div class="d-flex flex-column w-100">
      <div class="container__content">
        <div class="d-flex d-sm-flex container__content-title">
          <div class="container__cris-mobile">
            <img
              [src]="'assets/img/svg/cris-mobile.svg' | fileHash"
              alt=""
              loading="lazy"
              />
            </div>
            <p class="container__title" [innerHTML]="getTitle() | translate"></p>
          </div>
          <div>
            <p
              class="container__subtitle"
            [innerHTML]="
              '@INSTALLED-GAS-MODAL-SUBTITLE-' + getStep() | translate
            "
            ></p>
          </div>
        </div>
        <div>
          @if (getStep() === 1) {
            <div class="container__button">
              <qsc-button
                class="w-100"
                variant="green"
                [buttonText]="'@INSTALLED-GAS-MODAL-BUTTON-1' | translate"
                (buttonClick)="onMoreEquipments()"
                [customEventGA]="getCustomEventGA('@INSTALLED-GAS-MODAL-BUTTON-1')"
              ></qsc-button>
              <qsc-button
                class="w-100"
                variant="secondary_0"
                [buttonText]="'@INSTALLED-GAS-MODAL-BUTTON-2' | translate"
                (buttonClick)="onChangeOwnership()"
                [customEventGA]="getCustomEventGA('@INSTALLED-GAS-MODAL-BUTTON-2')"
              ></qsc-button>
              <qsc-button
                class="w-100"
                variant="ghostAlert"
                [buttonText]="'@INSTALLED-GAS-MODAL-BUTTON-3' | translate"
                (buttonClick)="onNeverUsedGas()"
                [customEventGA]="getCustomEventGA('@INSTALLED-GAS-MODAL-BUTTON-3')"
              ></qsc-button>
            </div>
          }

          @if (getStep() === 2) {
            <div class="container__button">
              <qsc-button
                class="w-100"
                variant="green"
                [buttonText]="'@INSTALLED-GAS-MODAL-BUTTON-4' | translate"
                (buttonClick)="onGoToServicesStore()"
                [customEventGA]="getCustomEventGA('@INSTALLED-GAS-MODAL-BUTTON-4')"
              ></qsc-button>
              <qsc-button
                class="w-100"
                variant="secondary"
                [buttonText]="'@INSTALLED-GAS-MODAL-BUTTON-5' | translate"
                (buttonClick)="onGoToHome()"
                [customEventGA]="getCustomEventGA('@INSTALLED-GAS-MODAL-BUTTON-5')"
              ></qsc-button>
            </div>
          }

          @if (getStep() === 3) {
            <div class="container__button">
              <qsc-button
                class="w-100"
                variant="green"
                [buttonText]="'@INSTALLED-GAS-MODAL-BUTTON-6' | translate"
                (buttonClick)="onGoToChangeOwnership()"
                [customEventGA]="getCustomEventGA('@INSTALLED-GAS-MODAL-BUTTON-6')"
              ></qsc-button>
              <qsc-button
                class="w-100"
                variant="secondary"
                [buttonText]="'@INSTALLED-GAS-MODAL-BUTTON-5' | translate"
                (buttonClick)="onGoToHome()"
                [customEventGA]="getCustomEventGA('@INSTALLED-GAS-MODAL-BUTTON-5')"
              ></qsc-button>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
