import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { environment } from '@env';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FlowService, ChatService, CookiesService } from '@shared/services';
import { FooterAnalytics } from '@models/analytics';
import { AppLinkDirective, EventTrackerDirective } from '@shared/directives';
import { RemoveSpacesPipe } from '@shared/pipes';
import { getUrlParams, removeSpecialCharacters } from '@shared/utils';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { ExpandableListComponent } from '../expandable-list/expandable-list.component';
import { filter } from 'rxjs';
import { FileHashPipe } from '@shared/pipes/file-hash/file-hash.pipe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

type LoginTypes = 'clienteComgas' | 'clienteAdm' | 'clienteConcessionaria';
type ExpandListButtonTypes = 'comgas' | 'services' | 'supportChannels';

@UntilDestroy()
@Component({
  selector: 'qsc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [
    EventTrackerDirective,
    RouterLink,
    SvgIconComponent,
    AppLinkDirective,
    TranslateModule,
    NgClass,
    NgStyle,
    RemoveSpacesPipe,
    FileHashPipe,
    ExpandableListComponent,
    NgTemplateOutlet,
  ],
})
export class FooterComponent implements OnInit {
  expandListButton: ExpandListButtonTypes | null = null;
  expandedListMarginLeft = 0;
  whatsappUrl = '';
  window: any;
  outOfFlowRoutes = {
    '/': true,
    '/residencias': true,
    '/predios': true,
    '/negocios': true,
    '/industrias': true,
    '/veiculos': true,
    '/duvidas-frequentes': true,
  };
  routesWithFloatingWrapper = [
    '/',
    '/residencias',
    '/predios',
    '/negocios',
    '/industrias',
    '/piscina-aquecida',
  ];
  hasFloatingButton = true;

  constructor(
    private readonly router: Router,
    private readonly flowService: FlowService,
    private readonly translateService: TranslateService,
    private readonly chatService: ChatService,
    private readonly cookiesService: CookiesService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.window = this.document.defaultView;
  }

  ngOnInit() {
    this.handleUrlParams(this.router.url);
    this.handleFloatingWrapper(this.router.url);
    this.routeEvents()
      .pipe(untilDestroyed(this))
      .subscribe((evt: NavigationEnd) => {
        const { url } = evt;
        this.handleUrlParams(url);
        this.handleFloatingWrapper(url);
      });
    this.getWhatsAppUrl();
  }

  expandList(targetType: ExpandListButtonTypes, targetButton?: HTMLElement) {
    if (this.expandListButton === targetType) {
      this.expandListButton = null;
    } else {
      this.expandListButton = targetType;
      if (targetButton) {
        this.calculateMarginLeft(targetButton);
      }
    }
  }

  calculateMarginLeft(targetButton: HTMLElement) {
    const linksDiv = this.document.querySelector('.links') as HTMLElement;
    if (linksDiv) {
      const linksRect = linksDiv.getBoundingClientRect();
      const buttonRect = targetButton.getBoundingClientRect();
      this.expandedListMarginLeft = buttonRect.left - linksRect.left;
    }
  }

  handleUrlParams(url: string) {
    const urlParams = getUrlParams(url.split('?')[1]);
    if (urlParams.has('secao')) {
      const isSupportChannels = urlParams.get('secao') === 'canais-atendimento';
      if (isSupportChannels) {
        this.expandListButton = 'supportChannels';
        this.router.navigate([this.router.url.split('?')[0]]);
      }
    }
  }

  routeEvents() {
    this.router.setUpLocationChangeListener();
    return this.router.events.pipe(
      filter((evt: any) => evt instanceof NavigationEnd)
    );
  }

  get footerAnalytics() {
    return FooterAnalytics;
  }

  handleFloatingWrapper(url: string): void {
    const routeWithoutParams = url.split('?')[0];
    this.hasFloatingButton =
      this.routesWithFloatingWrapper.includes(routeWithoutParams);
  }

  scrollTop() {
    if (!window) return;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  openLogin(loginType?: LoginTypes): string {
    const loginParam = loginType ? `?login=${loginType}` : `?login=''`;
    return `${environment.endpoints.comgasVirtual}${loginParam}&utm_source=qc&utm_medium=referral&utm_campaign=cliente-comgas-rodape&utm_content=botao`;
  }

  getActionByLoginType(loginType: LoginTypes): string {
    switch (loginType) {
      case 'clienteComgas':
        return FooterAnalytics.action.clienteComgas;
      case 'clienteAdm':
        return FooterAnalytics.action.administradoras;
      case 'clienteConcessionaria':
        return FooterAnalytics.action.clienteConcessionaria;
      default:
        return '';
    }
  }

  getGtmFlow(): string | undefined {
    if (this.outOfFlowRoutes[this.router.url]) return;
    return this.flowService.getGtmFlow();
  }

  getWhatsAppUrl(): void {
    this.translateService
      .get('@FOOTER-WHATSAPP-DA-CRIS-NUMERO')
      .subscribe((translated) => {
        this.whatsappUrl = `https://api.whatsapp.com/send?phone=55${removeSpecialCharacters(
          translated
        )}`;
      });
  }

  openChat(): void {
    this.chatService.openChat();
  }

  getCurrentYear(): number {
    return new Date().getFullYear();
  }

  openCookieConfiguration(): void {
    this.cookiesService.openConfiguration();
  }
}
