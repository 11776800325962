import { Injectable } from '@angular/core';
import { LegendService, LegendType } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class LegendFactory {
  constructor(private readonly legendService: LegendService) { }

  createLegend(type: LegendType): HTMLElement {
    switch (type) {
      case LegendType.SearchArea:
        return this.legendService.createLegendForSearchArea();
      case LegendType.Route:
        return this.legendService.createLegendForRoute();
      default:
        throw new Error('Invalid legend type');
    }
  }
}
