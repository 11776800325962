import { Component, Input, ViewEncapsulation } from '@angular/core';

import { GoogleTagManagerService } from '@core/index';
import { ButtonComponent, PictureComponent } from '@shared/components';
import { ISegment } from '@modules/home/models/home';
import { TranslateModule } from '@ngx-translate/core';
import { HOME_GTM_SEGMENTS, HomeAnalytics } from '@models/analytics';
import { Router, RouterLink } from '@angular/router';
import {
  ContentPageService,
  ProfileService,
  SegmentService,
} from '@shared/services';
import { BlogAnalytics } from '@models/analytics/blog-analytics';

@Component({
  selector: 'qsc-segment-card',
  standalone: true,
  imports: [RouterLink, ButtonComponent, PictureComponent, TranslateModule],
  templateUrl: './segment-card.component.html',
  styleUrls: ['./segment-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SegmentCardComponent {
  @Input() data: ISegment = {} as ISegment;
  @Input() fromBlog = false;

  constructor(
    private readonly googleTagManager: GoogleTagManagerService,
    private readonly router: Router,
    private readonly segmentService: SegmentService,
    private readonly profileService: ProfileService,
    private readonly contentPageService: ContentPageService
  ) {}

  handleCta() {
    if (!this.data.id) return;
    this.sendGtmEventOnHandleCta();
    this.segmentService.select(this.data.id);
    this.profileService.selectDefault(this.data.id);
    this.router.navigateByUrl(this.data.link);
  }

  sendGtmEventOnHandleCta() {
    if (this.contentPageService.isPage('blog')) {
      this.googleTagManager.sendCustomEvent(
        BlogAnalytics.category.blog,
        BlogAnalytics.action.ctaSegmentos,
        BlogAnalytics.label.getById(this.data.id)
      );
      return;
    }
    this.googleTagManager.sendCustomEvent(
      HomeAnalytics.category.tipoDeImovel,
      HOME_GTM_SEGMENTS[this.data.id],
      HomeAnalytics.label.clique
    );
  }

  sendGtmEventOnKnowMore(): void {
    if (this.contentPageService.isPage('blog')) {
      this.googleTagManager.sendCustomEvent(
        BlogAnalytics.category.blog,
        BlogAnalytics.action.saberMaisSegmentos,
        BlogAnalytics.label.getById(this.data.id)
      );
      return;
    }
    this.googleTagManager.sendCustomEvent(
      HomeAnalytics.category.tipoDeImovel,
      HOME_GTM_SEGMENTS[this.data.id],
      HomeAnalytics.label.saberMais
    );
  }

  isAddTagBr(description: string, maxCharacters = 111): boolean {
    return !!(description && description.length >= maxCharacters);
  }
}
