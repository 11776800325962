import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  private maintenanceStart: Date | null = null;
  private maintenanceEnd: Date | null = null;

  setMaintenanceWindow(startDate: Date, endDate: Date) {
    this.maintenanceStart = startDate;
    this.maintenanceEnd = endDate;
  }

  isMaintenanceWindow(): boolean {
    if (!this.maintenanceStart || !this.maintenanceEnd) {
      return false;
    }

    const now = new Date();
    return now >= this.maintenanceStart && now <= this.maintenanceEnd;
  }
}
