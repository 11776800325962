import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { ContentAnalytics, IContentData } from '@models/index';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgTemplateOutlet, NgClass } from '@angular/common';
import { GoogleTagManagerService } from '@core/google-tag-manager/google-tag-manager.service';
import { HelpfulAnswerComponent } from '../helpful-answer/helpful-answer.component';
import { PictureComponent } from '../picture/picture.component';
import { TrackContentClicks } from '@shared/services/track-content-clicks/track-content-clicks.service';

@Component({
  selector: 'qsc-content-accordion',
  templateUrl: './content-accordion.component.html',
  styleUrls: ['./content-accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet,
    MatExpansionModule,
    TranslateModule,
    HelpfulAnswerComponent,
    PictureComponent
],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentAccordionComponent implements AfterViewInit, OnDestroy {
  @ContentChild(TemplateRef) template?: TemplateRef<any>;
  @Input() titleFold = '';
  @Input() subtitleFold = '';
  @Input() items: IContentData[] = [];
  @Input() withHelpfulAnswer = false;
  @Input() accordionId: number | null = null;
  @Output() opened: EventEmitter<number> = new EventEmitter<number>();
  isAccordionOpened = false;

  constructor(
    private readonly googleTagManager: GoogleTagManagerService,
    private readonly translate: TranslateService,
    private readonly trackContentClicks: TrackContentClicks
  ) {}

  ngAfterViewInit(): void {
    this.trackContentClicks.startTrackingClickOnLinks();
  }

  ngOnDestroy(): void {
    this.trackContentClicks.stopTrackingClickOnLinks();
  }

  get contentAnalytics() {
    return ContentAnalytics;
  }

  onAccordionOpened(content: IContentData): void {
    this.isAccordionOpened = true;
    this.accordionId = content.id;
    this.opened.emit(content.id);
    this.sendGtmEventOnQuestion(
      content.title,
      this.contentAnalytics.label.abrir
    );

    this.trackContentClicks.updateAnswerLinkForGtmEvent(
      {
        id: content.id,
        question: content.title,
        answer: content.description ?? '',
      },
      ContentAnalytics.category.cardConteudo
    );
  }

  onAccordionClosed(content: IContentData): void {
    if (this.accordionId === content.id) {
      this.isAccordionOpened = false;
      this.accordionId = null;
    }
    this.sendGtmEventOnQuestion(
      content.title,
      this.contentAnalytics.label.fechar
    );
  }

  sendGtmEventOnQuestion(question: string, label: string): void {
    this.translate.get(question).subscribe((question) => {
      if (question) {
        this.googleTagManager.sendCustomEvent(
          ContentAnalytics.category.cardConteudo,
          question,
          label
        );
      }
    });
  }
}
