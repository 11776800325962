import { GoogleTagManagerService, fromUser } from '@core/index';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { combineDateAndTime } from '@shared/utils';
import {
  CategoryEnum,
  FlowIdsEnum,
  IEnviaLeadDadosRede,
  IEnviaLeadInput,
  IObterLeadInput,
  getCategory,
  getFlow,
} from '@models/index';
import { AddressMapper, PersonalDataMapper } from '@shared/mappers/index';
import { SwimmingPoolService } from '@modules/swimming-pool-content/services/swimming-pool.service';
import { EquipmentService } from '@modules/equipment-selection/services/equipment/equipment.service';
import { CategoryService } from '@shared/services/category/category.service';

@Injectable({
  providedIn: 'root',
})
export class LeadMapper {
  userData?: fromUser.IUser | null;

  constructor(
    private readonly store: Store,
    private readonly gtm: GoogleTagManagerService,
    private readonly categoryService: CategoryService,
    private readonly swimmingPoolService: SwimmingPoolService,
    private readonly equipmentService: EquipmentService,
    private readonly personalDataMapper: PersonalDataMapper,
    private readonly addressMapper: AddressMapper
  ) {
    this.store
      .select(fromUser.selectUserData)
      .subscribe((data) => {
        this.userData = data;
      });
  }

  createLeadInput(): IEnviaLeadInput {
    return {
      dadosPessoais: this.personalDataMapper.createPersonalData(),
      dadosEndereco: {
        ...this.addressMapper.createAddressData(),
        latitude: this.userData?.latitude,
        longitude: this.userData?.longitude,
      },
      parametrosMidia: this.gtm.getMediaParameters(),
      dadosRede: this.userData?.networkData as IEnviaLeadDadosRede,
      equipamentos: this.equipmentService.formatEquipmentsToNetworkAvailability(
        this.userData?.equipments ?? []
      ),
      perfil: this.userData?.tmeo
        ? CategoryEnum.TMEO
        : getCategory(this.categoryService),
      quantidade: this.userData?.numberOfUnits ?? '',
      protocolo: this.userData?.protocol ?? '',
      segTmeo: this.userData?.tmeo
        ? getCategory(this.categoryService)
        : undefined,
      dataHoraAgendamento: combineDateAndTime(
        this.userData?.scheduling?.selectedDate as string,
        this.userData?.scheduling?.selectedTime as string
      ),
      infoPedido: getFlow(
        this.swimmingPoolService.isSwimmingPoolFlow()
          ? FlowIdsEnum.SwimmingPool
          : FlowIdsEnum.Common
      ),
      consultor: this.userData?.scheduling?.consultor ?? '',
    };
  }

  createGetLeadInput(): IObterLeadInput {
    const unifiedAddress = this.userData?.unifiedAddress
      ? this.userData?.unifiedAddress
      : this.userData?.address;

    return {
      cep:
        this.userData?.unifiedAddress?.cep ?? this.userData?.address?.cep ?? '',
      numero: this.userData?.number ?? '',
      complemento:
        this.userData?.complement?.complemento ??
        this.userData?.optionalComplement ??
        '',
      logradouro: unifiedAddress?.logradouro,
      nome: this.userData?.name ?? '',
      telefone: this.userData?.phoneNumber ?? '',
    };
  }
}
