import { Injectable } from '@angular/core';
import { IMarkerData } from '../marker/marker-data';
import { UiService } from '@core/index';

@Injectable({
  providedIn: 'root',
})
export class DistanceService {
  circle?: google.maps.Circle;

  constructor(private readonly uiService: UiService) { }

  /**
   * Desenha um círculo no mapa com base em origem e destino ou somente origem.
   * @param map Instância do mapa onde o círculo será desenhado.
   * @param origin Ponto de origem (lat, lng).
   * @param destination Ponto de destino (lat, lng). Opcional.
   * @param radiusForSlider Raio em quilômetros, usado apenas quando não há destino. Opcional.
   */
  drawCircle(
    map: google.maps.Map,
    origin: { lat: number; lng: number },
    radiusForSlider?: number
  ): void {
    const center = origin;
    let radius = 0;

    if (radiusForSlider) {
      radius = radiusForSlider;
    }

    if (this.circle) {
      this.circle.setMap(null);
    }

    this.circle = new google.maps.Circle({
      center: center,
      radius: radius * 1000,
      map: map,
      fillColor: '#0981AE',
      fillOpacity: 0.2,
      strokeColor: '#0981AE',
      strokeOpacity: 0.5,
      strokeWeight: 2,
    });

    map.setCenter(center);
    map.setZoom(this.getZoomLevelForRadius(radius * 1000));
  }

  /**
   * Filtra os locais com base no círculo desenhado.
   * @param locations Lista de objetos com propriedades latitude e longitude.
   * @param origin Ponto de origem (lat, lng).
   * @param destination Ponto de destino (lat, lng). Opcional.
   * @param radiusForSlider Raio em quilômetros, usado apenas quando não há destino. Opcional.
   */
  filterLocations(
    locations: IMarkerData[],
    origin: { lat: number; lng: number },
    radiusForSlider?: number
  ): IMarkerData[] {
    const center = origin;
    let radius = 0;

    if (radiusForSlider) {
      radius = radiusForSlider;
    }

    return locations.filter((location) => {
      if (!location.latitude || !location.longitude) {
        return false;
      }

      const locationDistance = this.calculateDistance(center, {
        lat: location.latitude,
        lng: location.longitude,
      });

      return locationDistance <= radius;
    });
  }

  /**
   * Calcula a distância em quilômetros entre dois pontos geográficos.
   */
  private calculateDistance(
    origin: { lat: number; lng: number },
    destination: { lat: number; lng: number }
  ): number {
    const R = 6371; // Raio da Terra em km
    const toRad = (value: number) => (value * Math.PI) / 180;

    const dLat = toRad(destination.lat - origin.lat);
    const dLng = toRad(destination.lng - origin.lng);

    const lat1 = toRad(origin.lat);
    const lat2 = toRad(destination.lat);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  }

  /**
   * Determina o nível de zoom ideal para um determinado raio.
   */
  private getZoomLevelForRadius(radius: number): number {
    const factor = this.uiService.isMatchedBreakpoint('xs') ? 300 : 700;
    return Math.floor(16 - Math.log2(radius / factor));
  }

  /**
   * Remove o círculo do mapa, se existir.
   */
  removeCircle(): void {
    if (this.circle) {
      this.circle.setMap(null);
      this.circle = undefined;
    }
  }
}
