import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { AboutComponent } from '../../components/about/about.component';
import { TestimonialsComponent } from '../../components/testimonials/testimonials.component';
import { SegmentsComponent } from '../../components/segments/segments.component';
import { AdvantagesComponent } from '../../components/advantages/advantages.component';
import { BannersComponent } from '@modules/home/components/banners/banners.component';

import {
  ButtonComponent,
  FloatingWrapperComponent,
  QuickAccessComponent,
  ShortFaqComponent,
} from '@shared/components';
import { EventTrackerDirective } from '@shared/directives';
import { ContentAnalytics } from '@models/analytics';
import { ContentPageService } from '@shared/services';
import { default as quickAccessJson } from '@assets/data/quick-access.json';
import { default as bannerJson } from '@assets/home/data/banner.json';
import { default as advantagesJson } from '@assets/home/data/advantages.json';
import { default as aboutJson } from '@assets/home/data/about.json';
import { default as segmentsJson } from '@assets/home/data/segments.json';
import { default as faqJson } from '@assets/home/data/home-faq.json';
import { default as testimonialsJson } from 'src/assets/home/data/testimonials.json';
import { JsonLdComponent, SeoJson } from '@core/seo';
import { ISegment } from '@modules/home/models/home';

export type IBannerJson = typeof bannerJson.banners;

@Component({
  selector: 'qsc-home',
  templateUrl: './home.component.html',
  standalone: true,
  imports: [
    BannersComponent,
    SegmentsComponent,
    ShortFaqComponent,
    TestimonialsComponent,
    AboutComponent,
    QuickAccessComponent,
    AdvantagesComponent,
    FloatingWrapperComponent,
    ButtonComponent,
    JsonLdComponent,
    TranslateModule,
    RouterLink,
    EventTrackerDirective,
  ],
})
export class HomeComponent implements OnInit {
  quickLinks = quickAccessJson.quickLinks;
  bannerData = bannerJson.banners;
  segmentsData = segmentsJson.segments as ISegment[];
  advantagesData = advantagesJson;
  faqData = faqJson.faq;
  aboutData = aboutJson;
  testimonialsData = testimonialsJson;
  schemas = SeoJson.pages.home.schemas;

  constructor(private readonly contentPageService: ContentPageService) {}

  ngOnInit(): void {
    this.contentPageService.setContentPage('home');
  }

  get contentAnalytics() {
    return ContentAnalytics;
  }
}
