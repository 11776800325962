import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass, TitleCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  IComparativeContentColumn,
  IComparativeContentRow,
} from '@models/index';

@Component({
  selector: 'qsc-comparative-table',
  templateUrl: './comparative-table.component.html',
  styleUrls: ['./comparative-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, TitleCasePipe, TranslateModule],
})
export class ComparativeTableComponent {
  @Input() columns?: IComparativeContentColumn[];
  @Input() rows?: IComparativeContentRow[];
}
