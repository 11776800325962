<div class="testimonials-text-cards">
  <swiper
    [config]="config"
    (slideNextTransitionEnd)="onSlideNextTransitionEnd()"
    (slidePrevTransitionEnd)="onSlidePrevTransitionEnd()"
    >
    @for (testimonial of data; track testimonial.id) {
      <ng-template swiperSlide>
        <div class="testimonials-text-cards__item">
          <div class="testimonials-text-cards__profile">
            <qsc-picture
              class="testimonials-text-cards__photo"
            [png]="{
              mobile: testimonial.image.mobile?.png,
              desktop: testimonial.image.desktop?.png
            }"
            [webp]="{
              mobile: testimonial.image.mobile?.webp,
              desktop: testimonial.image.desktop?.webp
            }"
              [title]="testimonial.image.title ?? '' | translate"
              [alt]="testimonial.name | translate"
            ></qsc-picture>
            <div class="testimonials-text-cards__client">
              <p [innerHTML]="testimonial.name | translate"></p>
              <p [innerHTML]="testimonial.role | translate"></p>
            </div>
          </div>
          <p
            class="testimonials-text-cards__description"
            [innerHTML]="testimonial.description | translate"
          ></p>
        </div>
      </ng-template>
    }
  </swiper>
</div>
