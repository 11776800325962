<div class="input" [ngClass]="{ 'input--gap': outsideLabel }">
  <label [attr.for]="id" class="content-bold flex align-items-baseline"
    >{{
    required && outsideLabel
    ? (outsideLabel | translate) + " *"
    : (outsideLabel | translate)
    }}
    <ng-content select="[outsideLabelIcon]"
    /></label>
    <mat-form-field appearance="fill" [class.is-valid]="isValid()">
      @if (insideLabel) {
        <mat-label>{{ insideLabel | translate }}</mat-label>
      }
      <input
        matInput
        [attr.data-testid]="dataTestId"
        [id]="id"
        class="label-search-semibold"
        [type]="type"
        [pattern]="pattern"
        [formControl]="control"
        [placeholder]="placeholder | translate"
        [disabled]="disabled"
        [attr.disabled]="disabled"
        [mask]="format"
        [required]="required"
        [tabIndex]="tabIndex"
        [errorStateMatcher]="errorMatcher"
        #input
        />
        @if (loading) {
          <mat-icon matSuffix>
            <img alt="" class="icon icon-loading" src="/assets/img/loading.gif" />
          </mat-icon>
        }
        @if (prefixIcon.enable && (name === 'email' || name === 'phone')) {
          <mat-icon
            matPrefix
            >
            @if (!prefixIcon.iconClass && name === 'email') {
              <i
                aria-hidden="true"
                class="icon-email"
              ></i>
            }
            @if (!prefixIcon.iconClass && name === 'phone') {
              <i
                aria-hidden="true"
                class="icon-telefone_chat"
              ></i>
            }
            @if (prefixIcon.iconClass) {
              <i
                aria-hidden="true"
                [class]="prefixIcon.iconClass"
              ></i>
            }
          </mat-icon>
        }
        @if (control.touched && control.dirty && !loading) {
          <mat-icon matSuffix>
            @if (isValid() && showCheckIcon) {
              <i
                aria-hidden="true"
                class="icon-check_bola_preenchido"
              ></i>
            }
            @if (control.invalid) {
              <i
                aria-hidden="true"
                class="icon-erro_fechar"
              ></i>
            }
          </mat-icon>
        }

        @if (control.hasError('required')) {
          <mat-error>
            {{ "@ORTEC-CAMPO-REQUERIDO" | translate }}
          </mat-error>
        }
        @if (control.touched && control.hasError('maxlength')) {
          <mat-error>
            {{
            "@ORTEC-CAMPO-LIMITE-CARACTERES"
            | translate : { length: this.control.errors?.['maxlength'].requiredLength }
            }}
          </mat-error>
        }
        @if (
          control.invalid &&
          !control.hasError('required') &&
          !control.hasError('maxlength')
          ) {
          <mat-error
            class="d-flex align-items-center gap-2"
            >
            @if (invalidErrorIconSvg) {
              <qsc-svg-icon
                [icon]="invalidErrorIconSvg"
                width="1rem"
                height="1rem"
              ></qsc-svg-icon>
            }
            {{ invalidErrorMessage || "@ORTEC-CAMPO-INVALIDO" | translate }}
          </mat-error>
        }
      </mat-form-field>
    </div>
