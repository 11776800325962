import { Injectable } from '@angular/core';
import { MarkerStrategyFactory } from './marker-strategy-factory';
import { IMarkerData } from './marker-data';
import { MarkerTypeEnum } from './marker-type.enum';
import { InfoWindowService } from '../info-window/info-window.service';
import { UiService } from '@core/index';

@Injectable({
  providedIn: 'root',
})
export class MarkerService {
  markers: google.maps.marker.AdvancedMarkerElement[] = [];

  private currentInfoWindow: google.maps.InfoWindow | null = null;

  constructor(
    private readonly markerStrategyFactory: MarkerStrategyFactory,
    private readonly infoWindowService: InfoWindowService,
    private readonly uiService: UiService
  ) {}

  async createMarkerAsync(
    markerType: MarkerTypeEnum,
    data: IMarkerData,
    map?: google.maps.Map
  ): Promise<google.maps.marker.AdvancedMarkerElement> {
    const marker = await this.createAndConfigureMarker(markerType, data, map);
    this.addClickListenerToMarker(marker, data);
    return marker;
  }

  getMarkers(): google.maps.marker.AdvancedMarkerElement[] {
    return this.markers;
  }

  setMarkers(markers: google.maps.marker.AdvancedMarkerElement[]): void {
    this.markers = markers;
  }

  clearMarkers(): void {
    this.markers.forEach((marker) => (marker.map = null));
    this.markers = [];
  }

  addMarker(marker: google.maps.marker.AdvancedMarkerElement): void {
    this.markers.push(marker);
  }

  private addClickListenerToMarker(
    marker: google.maps.marker.AdvancedMarkerElement,
    data: IMarkerData
  ): void {
    if (!data.installation) return;
    marker.addListener('click', () => this.handleMarkerClick(marker, data));
  }

  private handleMarkerClick(
    marker: google.maps.marker.AdvancedMarkerElement,
    data: IMarkerData
  ): void {
    this.closeCurrentInfoWindow();
    this.openNewInfoWindow(marker, data);
  }

  private closeCurrentInfoWindow(): void {
    if (this.currentInfoWindow) {
      this.currentInfoWindow.close();
    }
  }

  private async openNewInfoWindow(
    marker: google.maps.marker.AdvancedMarkerElement,
    data: IMarkerData
  ) {
    this.currentInfoWindow = new google.maps.InfoWindow({
      content: await this.infoWindowService.createContent(data),
    });
    this.currentInfoWindow.open(marker.map, marker);

    if (this.currentInfoWindow.isOpen) {
      if (this.uiService.isMatchedBreakpoint('xs')) {
        this.applyInfoWindowStyle();

        setInterval(() => {
          this.applyInfoWindowStyle();
        }, 0);
      }
    }
  }

  private async createAndConfigureMarker(
    markerType: MarkerTypeEnum,
    data: IMarkerData,
    map?: google.maps.Map
  ): Promise<google.maps.marker.AdvancedMarkerElement> {
    const markerStrategy =
      this.markerStrategyFactory.createMarkerStrategy(markerType);
    const pinElement = await markerStrategy.createPinElement();
    return this.createAdvancedMarkerElement(pinElement, data, map);
  }

  private async createAdvancedMarkerElement(
    pinElement: HTMLImageElement,
    data: IMarkerData,
    map?: google.maps.Map
  ): Promise<google.maps.marker.AdvancedMarkerElement> {
    const { AdvancedMarkerElement } = (await google.maps.importLibrary(
      'marker'
    )) as google.maps.MarkerLibrary;

    const marker = new AdvancedMarkerElement({
      position: new google.maps.LatLng(
        Number(data.latitude),
        Number(data.longitude)
      ),
      title: data.name,
      map: map,
      content: pinElement,
    });

    (marker as any).data = data;

    return marker;
  }

  private applyInfoWindowStyle() {
    const collection = document.getElementsByClassName('info-window-content');
    const windows = Array.from(collection);

    for (const window of windows) {
      const parent = window.parentElement;
      if (parent) {
        parent.style.removeProperty('max-height');
        parent.style.removeProperty('overflow');
      }

      const grandParent = parent ? parent.parentElement : null;

      if (grandParent) {
        grandParent.style.removeProperty('max-height');
        grandParent.setAttribute('style', 'height: 200px !important');
      }
    }
  }
}
