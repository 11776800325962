import { Component, HostListener, Input, OnDestroy } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { TextToSpeechService } from '@shared/services';
import { ButtonComponent } from '../button/button.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { EventTrackerDirective } from '@shared/directives';
import { BlogAnalytics } from '@models/analytics/blog-analytics';
import { FileHashPipe } from '@shared/pipes/file-hash/file-hash.pipe';

@Component({
  selector: 'qsc-audio-reader',
  standalone: true,
  imports: [
    TranslateModule,
    ButtonComponent,
    SvgIconComponent,
    FileHashPipe,
    EventTrackerDirective
],
  templateUrl: './audio-reader.component.html',
  styleUrls: ['./audio-reader.component.scss'],
})
export class AudioReaderComponent implements OnDestroy {
  @Input() content = '';
  @Input() gaEventCategory?: string;

  isPlaying = false;
  isPaused = false;

  get blogAnalytics() {
    return BlogAnalytics;
  }

  constructor(private readonly speechService: TextToSpeechService) {}

  readContent() {
    if (!this.content) return;
    this.isPlaying = true;
    if (this.isPaused) {
      speechSynthesis.resume();
      this.isPaused = false;
    } else {
      this.speechService.speak(this.content);
    }
  }

  stopReading() {
    this.speechService.stop();
    this.isPlaying = false;
    this.isPaused = false;
  }

  pauseReading() {
    speechSynthesis.pause();
    this.isPaused = true;
  }

  resumeReading() {
    if (this.isPaused) {
      speechSynthesis.resume();
      this.isPaused = false;
    }
  }

  ngOnDestroy() {
    this.stopReading();
  }

  @HostListener('window:beforeunload')
  onBeforeUnload() {
    this.stopReading();
  }
}
