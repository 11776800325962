<div class="input input--select" [ngClass]="{ 'input--gap': outsideLabel }">
  <label [attr.for]="id" class="content-bold">{{
    required && outsideLabel
    ? (outsideLabel | translate) + " *"
    : (outsideLabel | translate)
  }}</label>
  <mat-form-field appearance="fill" [class.is-valid]="isValid">
    @if (insideLabel) {
      <mat-label>{{ insideLabel }}</mat-label>
    }
    @if (prefixIcon && !this.selectInput?.empty) {
      <mat-icon
        ><i
        aria-hidden="true"
        [class]="prefixIcon"
        [ngClass]="getPrefixIconColor(this.selectInput?.value, prefixIcon)"
        matTextPrefix
        ></i
      ></mat-icon>
    }
    <mat-select
      class="label-search-semibold"
      [id]="id"
      disableRipple
      [formControl]="control"
      [required]="required"
      [disabled]="disabled"
      [attr.data-testid]="dataTestId"
      panelClass="custom-select-panel"
      [disableOptionCentering]="true"
      (selectionChange)="onSelectionChange($event)"
      (openedChange)="onOpenedChange()"
      [placeholder]="
        (placeholder | translate) || '@SELECT-DEFAULT-PLACEHOLDER-LABEL'
          | translate
      "
      (opened)="onOpened()"
      #selectInput
      >
      <div class="options-container">
        @for (item of items; track item) {
          <mat-option
            class="option"
            [value]="item | translate"
            >
            @if (prefixIcon) {
              <mat-icon
                ><i
                aria-hidden="true"
                [class]="prefixIcon"
                [ngClass]="getPrefixIconColor(item, prefixIcon)"
                matPrefix
              ></i></mat-icon
              >
              }{{ toLabel(item) | translate }}
            </mat-option>
          }
          @if (lastFixedOption) {
            <mat-option
              class="last-option"
              [value]="lastOptionLabel"
              (click)="onLastOptionClick()"
              >
              {{ lastOptionLabel }}
            </mat-option>
          }
        </div>
      </mat-select>
      <mat-icon [ngClass]="{ 'rotate-y-180': isOpened }" matSuffix
        ><i
        aria-hidden="true"
        class="icon-seta_baixo"
        [ngClass]="{ 'icon-seta_baixo--disabled': disabled }"
        ></i
      ></mat-icon>
      @if (control.hasError('required')) {
        <mat-error>
          {{ "@SELECT-DEFAULT-REQUIRED-LABEL" | translate }}
        </mat-error>
      }
    </mat-form-field>
  </div>
