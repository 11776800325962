<div id="category" class="category">
  <div class="category__description">
    @if (
      [
      profileIdsEnum.Residences,
      profileIdsEnum.Commerce,
      profileIdsEnum.Industry
      ].includes(profileService.getProfileId()!)
      ) {
      <div
        [innerHTML]="'@CATEGORY-SELECTION-LABEL-1' | translate"
      ></div>
    }
    @if (profileService.getProfileId() === profileIdsEnum.Buildings) {
      <div
        [innerHTML]="'@CATEGORY-SELECTION-LABEL-2' | translate"
      ></div>
    }
    @switch (selectedCategoryId) {
      @case (categoryIdsEnum.HouseBuilt) {
        <span
          [innerHTML]="'@CATEGORY-SELECTION-CASA-CONSTRUIDA-LABEL' | translate"
        ></span>
      }
      @case (categoryIdsEnum.NewHome) {
        <span
          [innerHTML]="'@CATEGORY-SELECTION-NEW-HOME-LABEL' | translate"
        ></span>
      }
      @case (categoryIdsEnum.Resident) {
        <span
          [innerHTML]="'@CATEGORY-SELECTION-MORADOR-LABEL' | translate"
        ></span>
      }
      @case (categoryIdsEnum.Inhabited) {
        <span
          [innerHTML]="'@CATEGORY-SELECTION-SINDICO-LABEL' | translate"
        ></span>
      }
      @case (categoryIdsEnum.NewHousing) {
        <span
          [innerHTML]="'@CATEGORY-SELECTION-CONSTRUTORA-LABEL' | translate"
        ></span>
      }
      @case (categoryIdsEnum.Commercial) {
        <span
          [innerHTML]="'@CATEGORY-SELECTION-COMERCIO-LABEL' | translate"
        ></span>
      }
      @case (categoryIdsEnum.Industrial) {
        <span
          [innerHTML]="'@CATEGORY-SELECTION-INDUSTRIA-LABEL' | translate"
        ></span>
      }
    }
  </div>
</div>
