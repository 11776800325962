export const getTimeUnit = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;

  const minutes = Math.ceil(seconds / 60);

  let result = '';

  if (hours > 0) {
    result += `${hours} h`;
  }
  if (minutes > 0) {
    result += `${result ? ' ' : ''}${minutes} min`;
  }

  return result;
};
