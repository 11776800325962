<div class="input textarea" [ngClass]="{ 'input--gap': outsideLabel }">
  <label [attr.for]="id" class="content-bold">{{
    required && outsideLabel
    ? (outsideLabel | translate) + " *"
    : (outsideLabel | translate)
  }}</label>
  <mat-form-field appearance="fill">
    @if (insideLabel) {
      <mat-label>{{ insideLabel | translate }}</mat-label>
    }
    <textarea
      matInput
      #input
      [formControl]="control"
      [id]="id"
      [name]="name"
      [placeholder]="placeholder"
      [required]="required"
      [disabled]="disabled"
      [attr.maxlength]="maxLength"
      rows="3"
    ></textarea>
    <mat-hint align="end">{{ input.value.length }}/{{ maxLength }}</mat-hint>

    @if (control.hasError('required')) {
      <mat-error>
        {{ "@ORTEC-CAMPO-REQUERIDO" | translate }}
      </mat-error>
    }
    @if (control.touched && control.hasError('maxlength')) {
      <mat-error>
        {{
        "@ORTEC-CAMPO-LIMITE-CARACTERES"
        | translate : { length: this.control.errors?.['maxlength'].requiredLength }
        }}
      </mat-error>
    }
    @if (
      control.invalid &&
      !control.hasError('required') &&
      !control.hasError('maxlength')
      ) {
      <mat-error
        class="d-flex align-items-center gap-2"
        >
        {{ "@ORTEC-CAMPO-INVALIDO" | translate }}
      </mat-error>
    }
  </mat-form-field>
</div>
