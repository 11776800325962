import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationExtras,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { filter, pairwise } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigateService {
  private previousUrl = '';
  private currentUrl = '';

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly location: Location
  ) {
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects;
        this.currentUrl = events[1].urlAfterRedirects;
      });
  }

  navigateToPage(route: string, extras?: NavigationExtras): void {
    const currentQueryParams = this.activatedRoute.snapshot.queryParams;
    this.router.navigate([route], {
      queryParams: currentQueryParams,
      ...extras,
    });
  }

  navigateToPageWithState(route: string, state: any): void {
    const currentQueryParams = this.activatedRoute.snapshot.queryParams;
    this.router.navigate([route], {
      queryParams: currentQueryParams,
      state: state,
    });
  }

  hasQueryParam(param: string): boolean {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    return Object.hasOwn(queryParams, param) && queryParams[param] !== '';
  }

  goBack(): void {
    this.location.back();
  }

  getPreviousUrl(): string {
    return this.previousUrl;
  }

  getCurrentUrl(): string {
    return this.currentUrl;
  }
}
