import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'qsc-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class LoadingComponent implements OnInit, OnDestroy {
  @Input() loadingText = '';
  @Input() loadingStatus?: { success?: boolean; error?: boolean };
  private modalBackdrop: Element | null = null;
  translatedText?: string;

  constructor(
    private readonly bsModalRef: BsModalRef,
    private readonly translateService: TranslateService,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.getTranslatedText();
    this.modalBackdrop = document.querySelector('.modal-backdrop');
    if (this.modalBackdrop) {
      this.renderer.setStyle(this.modalBackdrop, 'opacity', 0);
    }
  }

  ngOnDestroy(): void {
    if (this.modalBackdrop) {
      this.renderer.removeStyle(this.modalBackdrop, 'opacity');
    }
    this.bsModalRef.hide();
  }

  getTranslatedText(): void {
    this.translatedText = this.translatedText = this.translateService.instant(
      this.bsModalRef.content?.length
        ? this.bsModalRef.content
        : this.loadingText
    );
  }
}
