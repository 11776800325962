<div
  class="benefit-card"
  [ngClass]="{
    'benefit-card--box': variant === 'box'
  }"
  >
  <div class="benefit-card__title-container">
    <div class="benefit-card__icon">
      @if (iconClass) {
        <i
          class="benefit-card__icon-class"
          [class]="iconClass"
        ></i>
      }
      @if (iconSvg) {
        <img
          class="benefit-card__icon-img"
          [src]="iconSvg | fileHash"
          [alt]="title"
          />
      }
    </div>

    <h3 class="benefit-card__title" [innerHTML]="title"></h3>
  </div>
  <p class="benefit-card__description" [innerHTML]="description"></p>
</div>
