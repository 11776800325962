import { Component, Input, ViewEncapsulation } from '@angular/core';

import {
  ButtonComponent,
  FirstBannerComponent,
  PictureComponent,
} from '@shared/components';
import { TranslateModule } from '@ngx-translate/core';
import { HomeAnalytics } from '@models/index';
import { GoogleTagManagerService } from '@core/index';
import { RouterLink } from '@angular/router';
import { IBannersData } from '@modules/home/models/home';

@Component({
  selector: 'qsc-campaign-banner',
  standalone: true,
  imports: [
    RouterLink,
    FirstBannerComponent,
    ButtonComponent,
    PictureComponent,
    TranslateModule
],
  templateUrl: './campaign-banner.component.html',
  styleUrls: ['./campaign-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignBannerComponent {
  @Input() banner?: IBannersData['campaign'];

  constructor(private readonly googleTagManager: GoogleTagManagerService) {}

  sendGtmEventToStartFlow(): void {
    this.googleTagManager.sendCustomEvent(
      HomeAnalytics.category.ctaBanner,
      HomeAnalytics.action.bannerNovaCampanha,
      HomeAnalytics.label.contratarAgora
    );
  }
}
