<div class="base__loading-container text-center">
  @if (!loadingStatus?.error && !loadingStatus?.success) {
    <div
      class="base__loading-status--default spinner-border text-light"
      role="status"
      >
      <span class="visually-hidden">Loading...</span>
    </div>
  }
  @if (loadingStatus?.success && !loadingStatus?.error) {
    <span
      class="base__loading-status--unicode text-light m-0 p-0"
      role="status"
      >&#x2713;</span
      >
    }
    @if (!loadingStatus?.success && loadingStatus?.error) {
      <span
        class="base__loading-status--unicode text-light m-0 p-0"
        role="status"
        F
        >&#x26A0;</span
        >
      }
      <p class="text-small-mobile-regular text-light m-0">
        {{ translatedText }}
      </p>
    </div>
