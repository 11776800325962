import { GoogleTagManagerService, fromUser } from '@core/index';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { IWebToCaseConfig, IWebToCaseField } from '@shared/services';
import { CategoryEnum, getCategory } from '@models/index';
import { environment } from '@env';
import { CategoryService } from '@shared/services/category/category.service';

@Injectable({
  providedIn: 'root',
})
export class SwimmingPoolMapper {
  userData?: fromUser.IUser | null;

  constructor(
    private readonly store: Store,
    private readonly categoryService: CategoryService,
    private readonly googleTagManager: GoogleTagManagerService
  ) {
    this.store
      .select(fromUser.selectUserData)
      .subscribe((data) => {
        this.userData = data;
      });
  }

  createSwimmingPoolInput(): IWebToCaseConfig {
    const config = environment.webToCaseConfig.swimmingPoolConfig;
    const data = this.userData;
    const mediaParameters = this.googleTagManager.getMediaParameters();

    const fields: IWebToCaseField[] = [
      { name: 'orgid', value: config.orgid },
      { name: 'retURL', value: config.retURL },
      { name: config.nome, value: data?.name },
      { name: config.email, value: data?.email },
      { name: config.celular, value: data?.phoneNumber },
      { name: config.cep, value: data?.address?.cep },
      { name: config.numero, value: data?.number },
      { name: config.complemento, value: data?.complement?.complemento },
      { name: config.apartamento, value: data?.apartment },
      { name: config.logradouro, value: data?.address?.logradouro },
      { name: config.bairro, value: data?.address?.bairro },
      { name: config.cidade, value: data?.address?.cidade },
      { name: config.estado, value: data?.address?.estado },
      { name: config.perfil, value: this.getSwimmingPoolCategory() },
      { name: config.protocolo, value: data?.protocol },
      { name: config.optIn, value: '1' },
      { name: config.campanha, value: mediaParameters?.campaign },
      { name: config.source, value: mediaParameters?.source },
      { name: config.medium, value: mediaParameters?.medium },
      { name: config.term, value: mediaParameters?.term },
      { name: config.gclid, value: mediaParameters?.gclid },
      { name: config.fbclid, value: mediaParameters?.fbclid },
      { name: config.canal, value: 'Piscina QC' },
    ];

    return {
      url: `${environment.webToCaseConfig.url}&orgId=${config.orgid}`,
      fields,
    };
  }

  getSwimmingPoolCategory(): string {
    const category = getCategory(this.categoryService);
    switch (category) {
      case CategoryEnum.Construida:
        return 'Casas';
      case CategoryEnum.Obra:
        return 'NHome';
      case CategoryEnum.Habitado:
      case CategoryEnum.Morador:
        return 'Prédios';
      case CategoryEnum.Construcao:
        return 'NewHousing';
      case CategoryEnum.Comercial:
        return 'Comércio';
      case CategoryEnum.Industrial:
        return 'Indústria';
      default:
        return '';
    }
  }
}
