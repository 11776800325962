import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  MapActionModalEnum,
  MapNotificationModalEnum,
} from '@shared/components';
import {
  GoogleMapsModalService,
  IMarkerData,
  WaypointService,
} from '@shared/services';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MarkerUtilsService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly waypointService: WaypointService,
    private readonly googleMapsModalService: GoogleMapsModalService
  ) {}

  createActionButtons(
    marker: google.maps.marker.AdvancedMarkerElement,
    data: IMarkerData,
    map: google.maps.Map
  ) {
    const overlay = new google.maps.OverlayView();
    overlay.onAdd = () => this.setupOverlay(overlay, marker, data);
    overlay.onRemove = () => this.removeOverlay(overlay);
    overlay.setMap(map);
  }

  removeActionButtons(): void {
    const addButtons = document.getElementsByClassName('waypoint-add-btn');
    if (addButtons) {
      Array.from(addButtons).forEach((button) => button.remove());
    }

    const removeButtons = document.getElementsByClassName(
      'waypoint-remove-btn'
    );
    if (removeButtons) {
      Array.from(removeButtons).forEach((button) => button.remove());
    }
  }

  private setupOverlay(
    overlay: google.maps.OverlayView,
    marker: google.maps.marker.AdvancedMarkerElement,
    data: IMarkerData
  ) {
    const div = this.createButtonContainer();
    const buttonPositive = this.createButton(
      'add',
      data,
      this.translateService.instant('@VEHICLES-CONTENT-MAPS-TOOLTIP-1')
    );
    const buttonNegative = this.createButton(
      'remove',
      data,
      this.translateService.instant('@VEHICLES-CONTENT-MAPS-TOOLTIP-2')
    );

    this.updateButtonVisibility(buttonPositive, buttonNegative, data);
    this.setupButtonEvents(buttonPositive, buttonNegative, data);

    div.appendChild(buttonPositive);
    div.appendChild(buttonNegative);

    overlay.getPanes()?.overlayMouseTarget.appendChild(div);

    overlay.draw = () => this.updateOverlayPosition(div, overlay, marker);
  }

  private createButtonContainer(): HTMLDivElement {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.zIndex = '10';
    div.addEventListener('mouseover', () => (div.style.zIndex = '1000'));
    div.addEventListener('mouseout', () => (div.style.zIndex = '10'));
    return div;
  }

  private createButton(
    type: 'add' | 'remove',
    data: IMarkerData,
    tooltip: string
  ): HTMLButtonElement {
    const button = document.createElement('button');
    button.setAttribute('data-title', tooltip);
    button.style.display = 'none';
    button.className = `waypoint-${type}-btn`;
    button.id = data.installation?.toString() ?? '';

    button.innerHTML = `<i aria-hidden="true" class="icon-sinal_${
      type === 'add' ? 'somar' : 'menos'
    }"></i>`;
    return button;
  }

  private updateButtonVisibility(
    addButton: HTMLButtonElement,
    removeButton: HTMLButtonElement,
    data: IMarkerData
  ) {
    const isWaypoint = this.waypointService.isInWaypoints(data);
    addButton.style.display = isWaypoint ? 'none' : 'block';
    removeButton.style.display = isWaypoint ? 'block' : 'none';
  }

  private setupButtonEvents(
    addButton: HTMLButtonElement,
    removeButton: HTMLButtonElement,
    data: IMarkerData
  ) {
    addButton.addEventListener('click', async (event) => {
      event.stopPropagation();
      await this.handleAddWaypointClick(event, data, addButton, removeButton);
    });

    removeButton.addEventListener('click', async (event) => {
      event.stopPropagation();
      await this.handleRemoveWaypointClick(
        event,
        data,
        addButton,
        removeButton
      );
    });
  }

  private async handleAddWaypointClick(
    event: Event,
    data: IMarkerData,
    buttonPositive: HTMLButtonElement,
    buttonNegative: HTMLButtonElement
  ) {
    const modal = this.googleMapsModalService.openActionModal(
      MapActionModalEnum.Add,
      data.name
    );

    modal.onHide?.pipe(take(1)).subscribe(async () => {
      if (modal.content.confirm) {
        this.waypointService.addWaypoint(data);
        this.updateButtonVisibility(buttonPositive, buttonNegative, data);
        this.waypointService.waypointsSubject.next(
          this.waypointService.getWaypoints()
        );
        this.googleMapsModalService.openNotificationModal(
          MapNotificationModalEnum.GastStationAdded
        );
      }
    });
  }

  private async handleRemoveWaypointClick(
    event: Event,
    data: IMarkerData,
    buttonPositive: HTMLButtonElement,
    buttonNegative: HTMLButtonElement
  ) {
    const modal = this.googleMapsModalService.openActionModal(
      MapActionModalEnum.Remove,
      data.name
    );

    modal.onHide?.pipe(take(1)).subscribe(async () => {
      if (modal.content.confirm) {
        this.waypointService.removeWaypoint(data);
        this.updateButtonVisibility(buttonPositive, buttonNegative, data);
        this.waypointService.waypointsSubject.next(
          this.waypointService.getWaypoints()
        );
        this.googleMapsModalService.openNotificationModal(
          MapNotificationModalEnum.GasStationRemoved
        );
      }
    });
  }

  private updateOverlayPosition(
    div: HTMLDivElement,
    overlay: google.maps.OverlayView,
    marker: google.maps.marker.AdvancedMarkerElement
  ) {
    const projection = overlay.getProjection();
    const position = projection.fromLatLngToDivPixel(marker.position!);

    if (position) {
      div.style.position = 'absolute';
      div.style.left = `${position.x - 25}px`;
      div.style.top = `${position.y - 35}px`;
    }
  }

  private removeOverlay(overlay: google.maps.OverlayView) {
    overlay.get('div')?.parentNode?.removeChild(overlay.get('div'));
  }
}
