<div class="input autocomplete" [ngClass]="{ 'input--gap': outsideLabel }">
  <label [htmlFor]="id" class="content-bold">{{
    required && outsideLabel
    ? (outsideLabel | translate) + " *"
    : (outsideLabel | translate)
  }}</label>
  <mat-form-field
    appearance="fill"
    [class.is-valid]="options.length || success"
    [class.is-disabled]="disabled"
    >
    @if (insideLabel) {
      <mat-label>{{ insideLabel | translate }}</mat-label>
    }
    @if (prefixIcon && this.input?.value) {
      <mat-icon
        ><i
        aria-hidden="true"
        [class]="prefixIcon"
        [ngClass]="getPrefixIconColor(this.input?.value ?? '', prefixIcon)"
        matTextPrefix
        ></i
      ></mat-icon>
    }
    <input
      [attr.data-testid]="dataTestId"
      [id]="id"
      [name]="name"
      type="text"
      [placeholder]="placeholder | translate"
      [attr.aria-label]="outsideLabel | translate"
      [formControl]="control"
      [required]="required"
      [disabled]="disabled"
      [matAutocomplete]="auto"
      [errorStateMatcher]="errorMatcher"
      [class.ng-pending]="searching"
      (input)="onInput()"
      class="truncate"
      matInput
      #input
      />

      @if (control.value) {
        <button
          mat-icon-button
          matSuffix
          title="Limpar campo"
          aria-label="Limpar"
          class="clear-button"
      (click)="
        control.setValue(''); clearButtonEvent.emit(); $event.stopPropagation()
      "
          >
          <mat-icon>close</mat-icon>
        </button>
      }

      <mat-autocomplete
        #auto="matAutocomplete"
        (opened)="onOpenedChange()"
        (closed)="onOpenedChange()"
        >
        @if (noData) {
          <mat-option
            disabled
            style="white-space: initial; line-height: initial; padding: 0.2rem 1rem"
            >
            <span [innerHTML]="'@NO-ADDRESS-FOUND' | translate"></span>
          </mat-option>
        }

        @if (useVirtualScroll) {
          @if (useVirtualScroll) {
            <cdk-virtual-scroll-viewport
              [itemSize]="10"
              [minBufferPx]="100"
              [maxBufferPx]="200"
              [style.height.px]="getViewportHeight(filteredOptions | async)"
              >
              <mat-option
          style="
            white-space: initial;
            line-height: initial;
            padding: 0.2rem 1rem;
          "
                *cdkVirtualFor="let option of filteredOptions | async"
                [value]="option"
                (onSelectionChange)="onSelectionChange(option)"
                >
                @if (prefixIcon && !this.input?.value) {
                  <mat-icon
                    ><i
                    aria-hidden="true"
                    [class]="prefixIcon"
                    [ngClass]="getPrefixIconColor(option ?? '', prefixIcon)"
                    matTextPrefix
                    ></i
                  ></mat-icon>
                }
                {{ option }}
              </mat-option>
            </cdk-virtual-scroll-viewport>
          }
        } @else {
          @for (option of filteredOptions | async; track option) {
            <mat-option
              style="white-space: initial; line-height: initial; padding: 0.2rem 1rem"
              [value]="option"
              (onSelectionChange)="onSelectionChange(option)"
              >
              {{ option }}
            </mat-option>
          }
        }
      </mat-autocomplete>

      @if (
        name === 'address' &&
        (((control.dirty || control.touched) &&
        !control.hasError('required') &&
        (options.length || success)) ||
        control.valid)
        ) {
        <mat-icon
          matSuffix
          >
          <i aria-hidden="true" class="icon-check_bola_preenchido"></i>
        </mat-icon>
      }
      @if (
        name === 'address' &&
        (control.dirty || control.touched) &&
        control.hasError('required')
        ) {
        <mat-icon
          matSuffix
          >
          <i aria-hidden="true" class="icon-erro_fechar"></i>
        </mat-icon>
      }
      @if (searching) {
        <mat-icon matSuffix>
          <img alt="" class="icon-loading" src="/assets/img/loading.gif" />
        </mat-icon>
      }
      @if (name === 'address') {
        <mat-icon matSuffix>
          @if (!success) {
            <i aria-hidden="true" class="icon-localizacao_gps"></i>
          }
          @if (success) {
            <i
              aria-hidden="true"
              class="icon-localizacao_gps is-success"
            ></i>
          }
        </mat-icon>
      }

      @if (name !== 'address') {
        <mat-icon
          [ngClass]="{ 'rotate-y-180': isOpened }"
          matSuffix
          ><i
          aria-hidden="true"
          class="icon-seta_baixo"
          [ngClass]="{ 'icon-seta_baixo--disabled': disabled }"
          ></i
        ></mat-icon>
      }

      @if (control.hasError('required')) {
        <mat-error>
          {{ "@ORTEC-CAMPO-REQUERIDO" | translate }}
        </mat-error>
      }
    </mat-form-field>
  </div>
