@if (figCaption) {
  <figure>
    <ng-container [ngTemplateOutlet]="pictureOnly"></ng-container>
    <figcaption>
      {{ figCaption | translate }}
    </figcaption>
  </figure>
} @else {
  <picture>
    @if (breakpoints) {
      @for (breakpoint of breakpoints | keyvalue | orderBy : '-key'; track breakpoint.key) {
        @if (breakpoint.key !== '0') {
          @if (breakpoint.value.webp) {
            <source
              [attr.srcset]="breakpoint.value.webp | fileHash"
              [attr.media]="'(min-width: ' + breakpoint.key + 'px)'"
              type="image/webp"
              />
          }
          @if (breakpoint.value.png) {
            <source
              [attr.srcset]="breakpoint.value.png | fileHash"
              [attr.media]="'(min-width: ' + breakpoint.key + 'px)'"
              />
          }
        }
      }
    }
    @if (webp?.desktop) {
      <source
        type="image/webp"
        media="(min-width: 62em)"
        [srcset]="webp?.desktop | fileHash"
        />
    }
    @if (png?.desktop) {
      <source
        media="(min-width: 62em)"
        [srcset]="png?.desktop | fileHash"
        />
    }
    @if(breakpoints?.['0']?.webp) {
      <source type="image/webp" [srcset]="breakpoints?.['0']?.webp | fileHash" />
    } @else {
      <source type="image/webp" [srcset]="webp?.mobile | fileHash" />
    }
    <img
      class="img-fluid"
      [ngClass]="{
        'w-100': fullWidth,
        'h-100': fullHeight,
        'object-fit-cover': cover
      }"
      [src]="
          (png?.mobile | fileHash) || (breakpoints?.['0']?.png | fileHash)
        "
      [alt]="alt || '' | translate"
      [title]="title || '' | translate"
      [loading]="lazy ? 'lazy' : 'eager'"
      />
    </picture>
  }

  <ng-template #pictureOnly>
    <picture>
      @if (breakpoints) {
        @for (breakpoint of breakpoints | keyvalue | orderBy : '-key'; track breakpoint.key) {
          @if (breakpoint.key !== '0') {
            @if (breakpoint.value.webp) {
              <source
                [attr.srcset]="breakpoint.value.webp | fileHash"
                [attr.media]="'(min-width: ' + breakpoint.key + 'px)'"
                type="image/webp"
                />
            }
            @if (breakpoint.value.png) {
              <source
                [attr.srcset]="breakpoint.value.png | fileHash"
                [attr.media]="'(min-width: ' + breakpoint.key + 'px)'"
                />
            }
          }
        }
      }

      @if (webp?.desktop) {
        <source
          type="image/webp"
          media="(min-width: 62em)"
          [srcset]="webp?.desktop | fileHash"
          />
      }
      @if (png?.desktop) {
        <source
          media="(min-width: 62em)"
          [srcset]="png?.desktop | fileHash"
          />
      }

      @if(breakpoints?.['0']?.webp) {
        <source type="image/webp" [srcset]="breakpoints?.['0']?.webp | fileHash" />
      } @else {
        <source type="image/webp" [srcset]="webp?.mobile | fileHash" />
      }

      <img
        class="img-fluid"
      [ngClass]="{
        'w-100': fullWidth,
        'h-100': fullHeight,
        'object-fit-cover': cover
      }"
      [src]="
          (png?.mobile | fileHash) || (breakpoints?.['0']?.png | fileHash)
        "
        [alt]="alt || '' | translate"
        [title]="title || '' | translate"
        [loading]="lazy ? 'lazy' : 'eager'"
        />
      </picture>
    </ng-template>
