<section class="segments container container-fhd">
  <div class="segments__title">
    <h2 [innerHTML]="title | translate"></h2>
  </div>

  <div class="row g-3 d-lg-none">
    @for (segment of data; track segment.id) {
    <div [ngClass]="['col col-sm-6 col-lg-3', gridItemClass]">
      <qsc-segment-card [data]="segment"></qsc-segment-card>
    </div>
    }
  </div>

  <div class="d-none d-lg-block">
    <swiper [config]="swiperConfig">
      @for (segment of data; track segment.id) {
      <ng-template swiperSlide>
        <qsc-segment-card [data]="segment"></qsc-segment-card>
      </ng-template>
      }
    </swiper>
  </div>
</section>
