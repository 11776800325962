import { Injectable } from '@angular/core';
import { IMarkerData } from '../marker/marker-data';
import { GooglePlacesService } from '../places/google-places.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class OpeningHoursService {
  constructor(
    private readonly placesService: GooglePlacesService,
    private readonly translateService: TranslateService
  ) { }

  async fetchOpeningHours(data: IMarkerData): Promise<void> {
    try {
      if (data.placeId && !data.openingHours && !data.updatedTime) {
        await this.placesService
          .getPlaceDetails(data.placeId, ['opening_hours'])
          .then((details) => {
            data.openingHours = details?.opening_hours;
            data.isOpen = !data.openingHours
              ? false
              : this.isOpenNow(data.openingHours.periods!);
            data.updatedTime = new Date();
          });
      }
    } catch (error) {
      console.error('Erro ao buscar horários de abertura:', error);
    }
  }

  async createOpeningHours(data: IMarkerData): Promise<HTMLElement> {
    return new Promise((resolve) => {
      const container = document.createElement('div');
      container.classList.add('info-window-content__availability');

      this.fetchOpeningHours(data).then(() => {
        if (!data.openingHours) {
          const unavailable = document.createElement('div');
          unavailable.classList.add('unavailable');
          unavailable.textContent = this.translateService.instant(
            '@VEHICLES-CONTENT-MAPS-INFO-WINDOW-LABEL-1'
          );
          container.append(unavailable);
          resolve(container);
          return;
        }

        if (data.isOpen) {
          const available = document.createElement('div');
          available.classList.add('available');
          available.textContent = this.translateService.instant(
            '@VEHICLES-CONTENT-MAPS-INFO-WINDOW-LABEL-2'
          );
          container.append(available);
          resolve(container);
          return;
        }

        if (!data.isOpen) {
          const closed = document.createElement('div');
          closed.classList.add('closed');

          const status = document.createElement('span');
          status.textContent = this.translateService.instant(
            '@VEHICLES-CONTENT-MAPS-INFO-WINDOW-LABEL-3'
          );

          const hour = document.createElement('span');
          hour.textContent =
            this.translateService.instant(
              '@VEHICLES-CONTENT-MAPS-INFO-WINDOW-LABEL-4'
            ) + `${this.checkStoreStatus(data)}`;

          closed.append(status, hour);
          container.append(closed);
        }
      });

      resolve(container);
    });
  }

  private getCurrentDayAndTime() {
    const now = new Date();
    const currentDay = now.getDay();
    const currentTime = now.getHours() * 100 + now.getMinutes();
    return { currentDay, currentTime };
  }

  private isOpenNow(
    preiods: google.maps.places.PlaceOpeningHoursPeriod[]
  ): boolean {
    const { currentDay, currentTime } = this.getCurrentDayAndTime();

    if (preiods.length === 1) {
      return true;
    }

    const daySchedule = preiods[currentDay];

    const openTime = this.getOpenTime(daySchedule);
    const closeTime = this.getCloseTime(daySchedule);

    return currentTime >= openTime && currentTime <= closeTime;
  }

  private getOpenTime(
    daySchedule: google.maps.places.PlaceOpeningHoursPeriod
  ): number {
    return daySchedule.open.hours * 100 + daySchedule.open.minutes;
  }

  private getCloseTime(
    daySchedule: google.maps.places.PlaceOpeningHoursPeriod
  ): number {
    const hours = daySchedule.close?.hours ?? 0;
    const minutes = daySchedule.close?.minutes ?? 0;
    return hours * 100 + minutes;
  }

  private checkStoreStatus(data: IMarkerData) {
    const { currentDay } = this.getCurrentDayAndTime();

    const daySchedule = data.openingHours?.periods![currentDay];

    return `${String(daySchedule?.open.hours).padStart(2, '0')}:${String(
      daySchedule?.open.minutes
    ).padStart(2, '0')}`;
  }
}
