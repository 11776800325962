import { fromUser } from '@core/index';
import { FlowTypeEnum } from '@modules/address-validation/models/address-validation';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { SwimmingPoolService } from '@modules/swimming-pool-content/services/swimming-pool.service';
import { IAddressConfirmationContext } from '@shared/services/address-confirmation/address-confirmation.service';
import { ModalTypeEnum } from '@models/index';
import { IOrtecContext } from '@modules/address-validation/services/ortec.service';

@Injectable({
  providedIn: 'root',
})
export class FlowMapper {
  userData?: fromUser.IUser | null;

  constructor(
    private readonly store: Store,
    private readonly swimmingPoolService: SwimmingPoolService
  ) {
    this.store
      .select(fromUser.selectUserData)
      .subscribe((data) => {
        this.userData = data;
      });
  }

  createOrtecContext(flowType: FlowTypeEnum, code: number): IOrtecContext {
    return {
      flowType,
      code,
      isSwimmingPool: this.swimmingPoolService.isSwimmingPoolFlow(),
    };
  }

  createAddressConfirmationContext(
    flowType: FlowTypeEnum,
    code: number,
    modalType: ModalTypeEnum
  ): IAddressConfirmationContext {
    return {
      flowType,
      code,
      modalType,
    };
  }
}
