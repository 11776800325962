<div class="container">
  <div class="d-flex flex-column align-items-lg-center">
    <div class="container__icon">
      <i aria-hidden="true" [class]="iconClass"></i>
    </div>
    <div>
      <div>
        <p class="container__title" [innerHTML]="title | translate"></p>
      </div>
      @if (subtitle) {
        <div>
          <p class="container__subtitle" [innerHTML]="subtitle | translate"></p>
        </div>
      }
    </div>
    <div class="container__button w-100">
      @for (option of options; track option.text) {
        <qsc-button
          class="w-100"
          [variant]="option.variant"
          [buttonText]="option.text | translate"
          (buttonClick)="onOptionClick(option.action)"
        ></qsc-button>
      }
    </div>
    @if (backButtonClick) {
      <div class="container__link">
        <a
          href="javascript:void(0)"
          aria-label="Link"
          (click)="onBackButtonClick()"
          [innerHTML]="'@QUESTION-BACK-BUTTON-TEXT' | translate"
        ></a>
      </div>
    }
  </div>
</div>
