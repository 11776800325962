<div class="option-buttons">
  @for (option of options; track option.label) {
    <button
      [disabled]="options.length === 1"
      class="option-buttons__button"
      [class.option-buttons__button--active]="$index === activeOptionIndex"
      (click)="changeOption($index)"
      >
      @if (option.iconClass) {
        <i [class]="option.iconClass"></i
          >
          }<span> {{ option.label | translate }}</span>
        </button>
      }
    </div>
