<button
  class="border-0 bg-transparent"
  [copyToClipboard]="valueToCopy"
  (copied)="notify()"
  >
  @if (!copied) {
    <qsc-svg-icon
      [width]="width"
      [height]="height"
      [icon]="variant === 'light' ? 'copy-light' : 'copy'"
    ></qsc-svg-icon>
  }
  @if (copied) {
    <qsc-svg-icon
      [width]="width"
      [height]="height"
      [icon]="variant === 'light' ? 'copy-filled-light' : 'copy-filled'"
    ></qsc-svg-icon>
  }
</button>
