import { Component, Input } from '@angular/core';

import { IOptimizedImageData } from '@models/index';
import { PictureComponent } from '../picture/picture.component';

@Component({
  selector: 'qsc-first-banner',
  standalone: true,
  imports: [PictureComponent],
  templateUrl: './first-banner.component.html',
  styleUrls: ['./first-banner.component.scss'],
})
export class FirstBannerComponent {
  @Input() image: IOptimizedImageData = {} as IOptimizedImageData;
}
