import { Injectable } from '@angular/core';
import {
  MapActionModalComponent,
  MapActionModalEnum,
  MapNotificationModalComponent,
  MapNotificationModalEnum,
} from '@shared/components';
import { ModalService } from '@shared/services/modal';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsModalService {
  constructor(private readonly bsModalService: BsModalService, private readonly modalService: ModalService) {
  }

  openNotificationModal(
    notificationModalType?: MapNotificationModalEnum | null
  ): void {
    if (notificationModalType == null) return;

    const modalOptions: ModalOptions = {
      initialState: {
        notificationModalType,
      },
      class: 'map-notification-modal modal-dialog-centered',
      backdrop: 'static',
      ignoreBackdropClick: true,
    };

    this.modalService.addModalToContainer('map', '.map-notification-modal');

    this.bsModalService.show(MapNotificationModalComponent, modalOptions);
  }

  openActionModal(
    actionModalType: MapActionModalEnum,
    gasStation = ''
  ): BsModalRef<any> {
    const modalOptions: ModalOptions = {
      initialState: {
        actionModalType,
        gasStation,
      },
      class: 'map-action-modal modal-dialog-centered',
      backdrop: 'static',
      ignoreBackdropClick: true,
    };

    this.modalService.addModalToContainer('map', '.map-action-modal');

    return this.bsModalService.show(MapActionModalComponent, modalOptions);
  }
}
