import { Injectable } from '@angular/core';
import { BootstrapService } from './bootstrap/bootstrap.service';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  constructor(private readonly bootstrapService: BootstrapService) {}

  private _skipGA = false;
  get skipGA() {
    return this._skipGA;
  }
  set skipGA(skipGA: boolean) {
    this._skipGA = skipGA;
  }

  private _analyticsKeys: any;
  get analyticsKeys() {
    return this._analyticsKeys;
  }
  set analyticsKeys(analyticsKeys: any) {
    this._analyticsKeys = analyticsKeys;
  }

  isMatchedBreakpoint(
    breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'lgPlus' | 'xl' | 'xxl'
  ): boolean {
    return this.bootstrapService.isMatched(breakpoint);
  }
}
