import { Component, OnInit } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ThemeService } from '@shared/services';
import { GoogleTagManagerService } from '@core/index';
import { HeaderAnalytics } from '@models/analytics';

@Component({
  selector: 'qsc-theme-switcher',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './theme-switcher.component.html',
  styleUrls: ['./theme-switcher.component.scss'],
})
export class ThemeSwitcherComponent implements OnInit {
  theme = 'default';

  constructor(
    private readonly themeService: ThemeService,
    private readonly googleTagManager: GoogleTagManagerService
  ) {}

  ngOnInit(): void {
    this.theme = this.themeService.getTheme();
  }

  toggleTheme() {
    const currentTheme = this.themeService.getTheme();
    const isDefaultTheme = currentTheme === 'default';
    if (isDefaultTheme) {
      this.sendGtmClickEvent();
    }
    const newTheme = isDefaultTheme ? 'contrast' : 'default';
    this.themeService.setTheme(newTheme);
    this.theme = this.themeService.getTheme();
  }

  checked() {
    return this.theme === 'contrast';
  }

  sendGtmClickEvent() {
    this.googleTagManager.sendCustomEvent(
      HeaderAnalytics.category.acessibilidade,
      HeaderAnalytics.action.contrasteAcessivel,
      HeaderAnalytics.label.clique
    );
  }
}
