import { Injectable } from '@angular/core';
import { EquipmentService } from '@modules/equipment-selection/services/equipment/equipment.service';
import { Store } from '@ngrx/store';
import { fromUser, userActions } from '@core/store';
import { FlowService } from '../flow/flow.service';
import { CategoryIdsEnum, FlowsAnalytics, SegmentIdsEnum } from '@models/index';
import { Router } from '@angular/router';
import { ProfileIdsEnum } from '@models/enums/profile.enum';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private profileId?: ProfileIdsEnum;

  constructor(
    private readonly store: Store,
    private readonly equipmentService: EquipmentService,
    private readonly flowService: FlowService,
    private readonly router: Router
  ) {
    this.selectProfileId();
  }

  selectDefault(segmentId?: SegmentIdsEnum): void {
    switch (segmentId) {
      case SegmentIdsEnum.Commerce:
        this.store.dispatch(
          userActions.updateUser({
            user: {
              profileId: ProfileIdsEnum.Commerce,
              categoryId: CategoryIdsEnum.Commercial,
            },
          })
        );
        break;
      case SegmentIdsEnum.Industry:
        this.store.dispatch(
          userActions.updateUser({
            user: {
              profileId: ProfileIdsEnum.Industry,
              categoryId: CategoryIdsEnum.Industrial,
            },
          })
        );
        break;
      case SegmentIdsEnum.Residences:
        this.store.dispatch(
          userActions.updateUser({
            user: {
              profileId: undefined,
              categoryId: undefined,
            },
          })
        );
        break;
    }
  }

  select(profileId: ProfileIdsEnum): void {
    this.store.dispatch(
      userActions.updateUser({
        user: {
          profileId,
        },
      })
    );
    this.equipmentService.removeData();
    this.selectProfileId();
  }

  private selectProfileId() {
    this.store
      .select(fromUser.selectProfileId)
      .subscribe((profileId) => {
        this.profileId = profileId;
      });
  }

  isBuildings(): boolean {
    return this.profileId === ProfileIdsEnum.Buildings;
  }

  isResidences(): boolean {
    return this.profileId === ProfileIdsEnum.Residences;
  }

  isCommerce(): boolean {
    return this.profileId === ProfileIdsEnum.Commerce;
  }

  isIndustry(): boolean {
    return this.profileId === ProfileIdsEnum.Industry;
  }

  get flowsAnalytics() {
    return FlowsAnalytics;
  }

  getGtmProfile(): string {
    return this.flowsAnalytics.category.alterarSegmento;
  }

  getGtmAction(): string {
    switch (this.router.url) {
      case '/validar-endereco':
        return this.flowsAnalytics.action.validarEndereco;
      case '/dados-pessoais':
        return this.flowsAnalytics.action.dadosPessoais;
      case '/agendamento/agendar':
        return this.flowsAnalytics.action.agendamento;
      default:
        return '';
    }
  }

  getGtmLabel(profileId?: ProfileIdsEnum): string {
    const profileIdToCompare = profileId ?? this.profileId;

    if (profileIdToCompare === ProfileIdsEnum.Buildings) {
      return this.flowsAnalytics.label.predios;
    }
    if (profileIdToCompare === ProfileIdsEnum.Residences) {
      return this.flowsAnalytics.label.residencias;
    }
    if (profileIdToCompare === ProfileIdsEnum.Commerce) {
      return this.flowsAnalytics.label.negocios;
    }
    if (profileIdToCompare === ProfileIdsEnum.Industry) {
      return this.flowsAnalytics.label.industrias;
    }
    return '';
  }

  getGtmFlow(): string | undefined {
    return this.flowService.getGtmFlow();
  }

  getProfileId(): ProfileIdsEnum | undefined {
    return this.profileId;
  }
}
