import { Component, Input, ViewEncapsulation } from '@angular/core';

import SwiperCore, {
  SwiperOptions,
  Pagination,
  Navigation,
  Autoplay,
} from 'swiper';
import { SwiperModule } from 'swiper/angular';
import { CampaignBannerComponent } from '../campaign-banner/campaign-banner.component';
import { IBannersData } from '@modules/home/models/home';

SwiperCore.use([Pagination, Navigation, Autoplay]);

@Component({
  selector: 'qsc-banners',
  standalone: true,
  imports: [SwiperModule, CampaignBannerComponent],
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BannersComponent {
  @Input() data?: IBannersData;

  swiperConfig: SwiperOptions = {
    autoplay: true,
    navigation: false,
    pagination: {
      clickable: true,
    },
  };
}
