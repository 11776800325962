import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';
import { FlowsAnalytics, SegmentIdsEnum } from '@models/index';
import { ProfileService, SegmentService } from '@shared/services';
import { default as segmentsDataJson } from 'src/assets/home/data/segments.json';
import { SegmentCardComponent } from '../segment-card/segment-card.component';
import { GoogleTagManagerService } from '@core/index';
import { NavigateService } from '@shared/services/navigate/navigate.service';
import { SwimmingPoolService } from '@modules/swimming-pool-content/services/swimming-pool.service';
import { CategoryService } from '@shared/services/category/category.service';
import { ISegment } from '@modules/home/models/home';

@Component({
  selector: 'qsc-select-segment',
  templateUrl: './select-segment.component.html',
  styleUrls: ['./select-segment.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, SegmentCardComponent, TranslateModule],
})
export class SelectSegmentComponent implements OnInit {
  @Input() items = segmentsDataJson.segments as ISegment[];
  title = '';
  filteredItems: ISegment[] = [];

  isSwimmingPoolFlow = this.swimmingPoolService.isSwimmingPoolFlow();

  constructor(
    public readonly bsModalRef: BsModalRef,
    private readonly segmentService: SegmentService,
    private readonly categoryService: CategoryService,
    private readonly profileService: ProfileService,
    private readonly router: Router,
    private readonly googleTagManager: GoogleTagManagerService,
    private readonly navigateService: NavigateService,
    protected readonly swimmingPoolService: SwimmingPoolService
  ) {}

  ngOnInit(): void {
    this.chooseTitle();
    this.filterItems();
  }

  get flowsAnalytics() {
    return FlowsAnalytics;
  }

  handle(segmentId: SegmentIdsEnum): void {
    this.segmentService.select(segmentId);
    this.profileService.selectDefault(segmentId);
    this.categoryService.selectDefault(segmentId);
    this.sendGtmEventOnClick();
    this.navigateService.navigateToPage('/validar-endereco');
  }

  backToHome(): void {
    this.router.navigate(['/']);
  }

  sendGtmEventOnClick(): void {
    this.googleTagManager.sendCustomEvent(
      FlowsAnalytics.category.selecionarSegmento,
      FlowsAnalytics.action.confirmarSegmento,
      this.segmentService.getGtmLabel()
    );
  }

  chooseTitle(): void {
    if (this.isSwimmingPoolFlow) {
      this.title = '@HOME-SEGMENTOS-CTA-TEXTO-PISCINA';
    } else {
      this.title = '@HOME-SEGMENTOS-CTA-TEXTO';
    }
  }

  filterItems(): void {
    if (this.isSwimmingPoolFlow) {
      this.filteredItems = this.items.filter(
        (item) => item.id !== SegmentIdsEnum.Industry && item.imageUrl.pools
      );
    } else {
      this.filteredItems = this.items.filter((item) => item.imageUrl.default);
    }
  }
}
