@if (data) {
  <div class="solution-card">
    <div>
      @if (data.iconSvg) {
        <img
          class="solution-card__icon-img"
          [src]="data.iconSvg | fileHash"
          alt=""
          />
      }
      @if (data.iconClass) {
        <i
          aria-hidden="true"
          class="solution-card__icon-class"
          [class]="data.iconClass"
        ></i>
      }
    </div>
    <h3 class="solution-card__title" [innerHTML]="data.title | translate"></h3>
    <div
      class="solution-card__description"
      [innerHTML]="data.description ?? '' | translate"
    ></div>
  </div>
}
