import { Injectable } from '@angular/core';
import {
  MapFactory,
  MapSettings,
  MarkerService,
  DirectionsService,
  DirectionsRendererService,
  NavigationService,
  LegendFactory,
  MarkerTypeEnum,
  IMarkerData,
  LegendType,
  WaypointService,
  WaypointUtilsService,
  MarkerUtilsService,
  LegendService,
  GooglePlacesService
} from '@shared/services';
import { DistanceService } from './distance/distance.service';
import { OpeningHoursService } from './opening-hours/opening-hours.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsFacade {
  constructor(
    private readonly mapFactory: MapFactory,
    private readonly markerService: MarkerService,
    public readonly mapSettings: MapSettings,
    private readonly directionsService: DirectionsService,
    private readonly directionsRendererService: DirectionsRendererService,
    private readonly navigationService: NavigationService,
    private readonly legendFactory: LegendFactory,
    public readonly waypointService: WaypointService,
    private readonly waypointUtilsService: WaypointUtilsService,
    private readonly markerUtilsService: MarkerUtilsService,
    private readonly legendService: LegendService,
    private readonly googlePlacesService: GooglePlacesService,
    private readonly distanceService: DistanceService,
    private readonly openingHoursService: OpeningHoursService
  ) { }

  async createMapAsync(mapElement: string): Promise<google.maps.Map> {
    return await this.mapFactory.createMapAsync(mapElement);
  }

  async initializeDirectionsService(): Promise<void> {
    return await this.directionsService.initializeService();
  }

  async initializeDirectionsRendererService(
    map: google.maps.Map
  ): Promise<void> {
    return await this.directionsRendererService.initializeRenderer(map);
  }

  async createMarkerAsync(
    markerType: MarkerTypeEnum,
    data: IMarkerData,
    map?: google.maps.Map
  ): Promise<google.maps.marker.AdvancedMarkerElement> {
    return await this.markerService.createMarkerAsync(markerType, data, map);
  }

  getMarkers(): google.maps.marker.AdvancedMarkerElement[] {
    return this.markerService.getMarkers();
  }

  setMarkers(markers: google.maps.marker.AdvancedMarkerElement[]): void {
    this.markerService.setMarkers(markers);
  }

  clearMarkers(): void {
    this.markerService.clearMarkers();
  }

  addMarker(marker: google.maps.marker.AdvancedMarkerElement): void {
    this.markerService.addMarker(marker);
  }

  async createRoute(
    origin: string,
    destination: string,
    waypoints: google.maps.DirectionsWaypoint[]
  ): Promise<google.maps.DirectionsResult | null> {
    return await this.directionsService.createRoute(
      origin,
      destination,
      waypoints
    );
  }

  startNavigation(
    origin: string,
    destination: string,
    waypoints: google.maps.DirectionsWaypoint[]
  ): void {
    this.navigationService.startNavigation(origin, destination, waypoints);
  }

  async renderRoute(directionsResult: google.maps.DirectionsResult | null) {
    return await this.directionsRendererService.renderRoute(directionsResult);
  }

  clearRoute(): void {
    this.directionsRendererService.clearRoute();
  }

  createLegendForRoute(): HTMLElement {
    return this.legendFactory.createLegend(LegendType.Route);
  }

  createLegendForSearchArea(): HTMLElement {
    return this.legendFactory.createLegend(LegendType.SearchArea);
  }

  removeLegends(): void {
    this.legendService.removeLegends();
  }

  getWaypoints(): google.maps.DirectionsWaypoint[] {
    return this.waypointService.getWaypoints();
  }

  clearWaypoints(): void {
    this.waypointService.clearWaypoints();
  }

  getOptimizedWaypoints(): google.maps.DirectionsWaypoint[] {
    return this.waypointService.getOptimizedWaypoints();
  }

  setOptimizedWaypoints(waypoints: google.maps.DirectionsWaypoint[]): void {
    this.waypointService.setOptimizedWaypoints(waypoints);
  }

  clearWaypointsInputs(): void {
    this.waypointUtilsService.clearWaypointsInputs();
  }

  addWaypointContainer(data: IMarkerData[]): void {
    this.waypointUtilsService.addWaypointContainer(data);
  }

  createActionButtons(
    marker: google.maps.marker.AdvancedMarkerElement,
    data: IMarkerData,
    map: google.maps.Map
  ): void {
    this.markerUtilsService.createActionButtons(marker, data, map);
  }

  removeActionButtons(): void {
    this.markerUtilsService.removeActionButtons();
  }

  searchPlace(query: string): Promise<google.maps.places.PlaceResult[]> {
    return this.googlePlacesService.searchPlace(query);
  }

  getPlaceDetails(
    placeId: string,
    fields?: google.maps.places.PlaceDetailsRequest['fields']
  ): Promise<google.maps.places.PlaceResult> {
    return this.googlePlacesService.getPlaceDetails(placeId, fields);
  }

  drawCircle(
    map: google.maps.Map,
    origin: { lat: number; lng: number },
    radiusForSlider?: number
  ): void {
    this.distanceService.drawCircle(map, origin, radiusForSlider);
  }

  filterLocations(
    locations: IMarkerData[],
    origin: { lat: number; lng: number },
    radiusForSlider?: number
  ): IMarkerData[] {
    return this.distanceService.filterLocations(
      locations,
      origin,
      radiusForSlider
    );
  }

  removeDistanceCircle() {
    this.distanceService.removeCircle();
  }

  async fetchOpeningHours(data: IMarkerData): Promise<void> {
    await this.openingHoursService.fetchOpeningHours(data);
  }
}
