import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgClass, DOCUMENT } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { default as gasStationMapJson } from '@assets/vehicles-content/data/mapa-postos.json';

@Component({
  selector: 'qsc-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatIconModule,
    NgClass,
    TranslateModule
],
})
export class SelectComponent implements AfterViewInit, AfterContentChecked {
  @Input() control = new FormControl();
  @Input() id = '';
  @Input() items: any = [];
  @Input() required = false;
  @Input() outsideLabel = '';
  @Input() insideLabel = '';
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() lastFixedOption = false;
  @Input() lastOptionLabel = '';
  @Input() prefixIcon = '';
  @Input() dataTestId = '';
  isValid = false;
  isOpened = false;
  gasStationMapData = gasStationMapJson;

  @Output() selectionChangeEvent = new EventEmitter();
  @Output() lastOptionClickEvent = new EventEmitter();

  @ViewChild(MatSelect)
  selectInput: MatSelect | undefined;

  constructor(
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isValid = this.control.valid;
    }, 0);

    setInterval(() => {
      this.configureLastFixedOption();
    });
  }

  ngAfterContentChecked(): void {
    this.stylizeLastFixedOption();
  }

  onOpenedChange(): void {
    this.isOpened = !this.isOpened;
  }

  onSelectionChange(option: any): void {
    this.selectionChangeEvent.emit(option.value);
  }

  toLabel(item: any): string {
    if (typeof item['toLabel'] === 'function') {
      return item.toLabel();
    }
    return item;
  }

  onLastOptionClick(): void {
    this.lastOptionClickEvent.emit();
  }

  onOpened(): void {
    this.configureLastFixedOption();
  }

  select(option: string): void {
    this.selectInput?.options.find((f) => f.value === option)?.select();
  }

  selectLast(): void {
    this.selectInput?.options.last.select();
  }

  configureLastFixedOption = () => {
    if (!this.lastFixedOption) return;
    const container = this.document.querySelector('.mat-mdc-select-panel');
    if (!container) return;
    this.renderer.addClass(container, 'listbox');
  };

  stylizeLastFixedOption(): void {
    const lastSelectedOption = this.document.querySelector(
      '.mat-mdc-select-value-text'
    );
    if (!lastSelectedOption) return;
    this.selectInput?.options.map((item: any) => {
      if (item.selected && item.value === this.lastOptionLabel) {
        this.renderer.addClass(lastSelectedOption, 'last-option-selected');
      } else {
        this.renderer.removeClass(lastSelectedOption, 'last-option-selected');
      }
    });
  }

  getPrefixIconColor(gasStation: string, prefixIcon: string): string {
    if (prefixIcon === 'icon-localizacao_gps') {
      const gasStationData = this.gasStationMapData.find(
        ({ name }) => name === gasStation
      );
      return gasStationData?.highFlow ? 'color-primary' : 'color-error';
    }

    return '';
  }
}
