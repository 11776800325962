import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IDevice } from '@models/index';
import { TranslateModule } from '@ngx-translate/core';
import {
  AnimatedNumberComponent,
  CardMoreAboutComponent,
} from '@shared/components';
import { FileHashPipe } from '@shared/pipes/file-hash/file-hash.pipe';
import SwiperCore, { SwiperOptions, Pagination } from 'swiper';
import { SwiperModule } from 'swiper/angular';

SwiperCore.use([Pagination]);

interface IAboutData {
  title: string;
  cards: {
    title: string;
    description: string;
    iconClass: string;
    variant: string;
    toUrl: string;
  }[];
  stepsTitle: string;
  stepsImages: IDevice;
  steps: string[];
  obs: string;
  numbers: {
    value: string;
    legend: string;
    isPercentage?: boolean;
    icon: string;
  }[];
}

@Component({
  selector: 'qsc-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  standalone: true,
  imports: [
    FileHashPipe,
    AnimatedNumberComponent,
    CardMoreAboutComponent,
    SwiperModule,
    TranslateModule,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AboutComponent {
  @Input() data: IAboutData = {} as IAboutData;

  swiperConfig: SwiperOptions = {
    slidesPerView: 0.47,
    pagination: true,
    slidesPerGroup: 1,
    breakpoints: {
      375: {
        slidesPerView: 0.55,
      },
      425: {
        slidesPerView: 0.61,
      },
      475: {
        slidesPerView: 0.7,
      },
      576: {
        slidesPerView: 0.8,
      },
      626: {
        slidesPerView: 0.9,
      },
      680: {
        slidesPerView: 1,
      },
    },
  };
}
