import { Component, Input } from '@angular/core';


@Component({
  selector: 'qsc-ds-spinner',
  standalone: true,
  imports: [],
  templateUrl: './ds-spinner.component.html',
  styleUrls: ['./ds-spinner.component.scss'],
})
export class DsSpinnerComponent {
  @Input() text = 'Carregando...';
}
