import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '@shared/services/login/login.service';
import { WindowRefService } from '@shared/services/window-ref/window-ref.service';

@Directive({
  selector: '[qscAdvancedRouterLink]',
  standalone: true,
})
export class AdvancedRouterLinkDirective {
  @Input('qscAdvancedRouterLink') url = '';
  loginType = '';

  constructor(
    private router: Router,
    private el: ElementRef,
    private loginService: LoginService,
    private windowRef: WindowRefService
  ) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.preventDefault();
    this.handleAction();
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      this.handleAction();
    }
  }

  handleAction() {
    if (this.url.startsWith('login:')) {
      this.handleLoginUrl();
    } else if (this.url.startsWith('http')) {
      this.handleExternalUrl();
    } else {
      this.handleInternalUrl();
    }
  }

  handleLoginUrl() {
    this.loginService.openModalLogin(this.loginType);
  }

  handleExternalUrl() {
    this.windowRef.nativeWindow.open(this.url, '_blank');
  }

  handleInternalUrl() {
    this.router.navigateByUrl(this.url);
  }
}
