import { Component, inject, OnInit } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { FlowsAnalytics } from '@models/analytics';
import { GoogleTagManagerService } from '@core/index';
import { environment } from '@env';

@Component({
  selector: 'qsc-maintenance',
  standalone: true,
  imports: [ButtonComponent, TranslateModule],
  templateUrl: './maintenance.component.html',
  styleUrl: './maintenance.component.scss',
})
export class MaintenanceComponent implements OnInit {
  urlWithParams = '';
  googleTagManager = inject(GoogleTagManagerService);

  ngOnInit(): void {
    this.initializeUrlWithParams();
  }

  private initializeUrlWithParams(): void {
    this.urlWithParams = `${environment.endpoints.comgasVirtual}?utm_source=qc&utm_medium=manutencao&utm_content=botao-sou-cliente-comgas`;
  }

  sendGtmEvent() {
    this.googleTagManager.sendCustomEvent(
      FlowsAnalytics.category.telaManutencao,
      FlowsAnalytics.action.jaSouCliente,
      FlowsAnalytics.label.clique
    );
  }
}
