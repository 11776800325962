<section class="maintenance pt-3 pt-md-5 pb-stack-xs pb-md-stack-vl">
  <div class="maintenance__img-container">
    <img
      class="img-fluid"
      src="assets/img/worker3_cg.png"
      alt="Desenho de um homem com uma chave inglesa"
    />
  </div>
  <div class="d-flex flex-column gap-3">
    <h2
      class="maintenance__title mb-0"
      [innerHtml]="'@MAINTENANCE-TITLE' | translate"
    ></h2>
    <p
      class="maintenance__description"
      [innerHtml]="'@MAINTENANCE-DESCRIPTION' | translate"
    ></p>
    <qsc-button
      variant="green"
      elementType="a"
      [href]="urlWithParams"
      [buttonText]="'@MAINTENANCE-CTA' | translate"
      (buttonClick)="sendGtmEvent()"
    />
  </div>
</section>
