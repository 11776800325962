import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from '@core/google-tag-manager/google-tag-manager.service';
import { Store } from '@ngrx/store';
import { fromUser, userActions } from '@core/store';
import { EventFlowGtmByCategoryId } from '@models/gtm';
import { CategoryIdsEnum, SegmentIdsEnum } from '@models/enums';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private userData?: fromUser.IUser | null;

  constructor(
    private readonly store: Store,
    private readonly gtm: GoogleTagManagerService
  ) {
    this.store
      .select(fromUser.selectUserData)
      .subscribe((userData) => {
        this.userData = userData;
      });
  }

  selectDefault(segmentId?: SegmentIdsEnum): void {
    switch (segmentId) {
      case SegmentIdsEnum.Commerce:
        this.store.dispatch(
          userActions.updateUser({
            user: {
              categoryId: CategoryIdsEnum.Commercial,
            },
          })
        );
        break;
      case SegmentIdsEnum.Industry:
        this.store.dispatch(
          userActions.updateUser({
            user: {
              categoryId: CategoryIdsEnum.Industrial,
            },
          })
        );
        break;
      case SegmentIdsEnum.Residences:
        this.store.dispatch(
          userActions.updateUser({
            user: {
              categoryId: undefined,
            },
          })
        );
        break;
    }
    this.clearNumberOfUnits();
  }

  select(categoryId?: CategoryIdsEnum): void {
    this.store.dispatch(
      userActions.updateUser({
        user: {
          categoryId,
        },
      })
    );
    this.clearNumberOfUnits();
  }

  clearNumberOfUnits(): void {
    if (!this.isNewHousing() && !this.isInhabited()) {
      if (this.userData && 'moreThanOneApartment' in this.userData) {
        this.store.dispatch(
          userActions.updateUser({
            user: { moreThanOneApartment: undefined },
          })
        );
      }

      if (this.userData && 'numberOfUnits' in this.userData) {
        this.store.dispatch(
          userActions.updateUser({
            user: { numberOfUnits: undefined },
          })
        );
      }
    }
  }

  isBuilt(): boolean {
    return this.userData?.categoryId === CategoryIdsEnum.HouseBuilt;
  }

  isNewHome(): boolean {
    return this.userData?.categoryId === CategoryIdsEnum.NewHome;
  }

  isInhabited(): boolean {
    return this.userData?.categoryId === CategoryIdsEnum.Inhabited;
  }

  isResident(): boolean {
    return this.userData?.categoryId === CategoryIdsEnum.Resident;
  }

  isNewHousing(): boolean {
    return this.userData?.categoryId === CategoryIdsEnum.NewHousing;
  }

  isCommercial(): boolean {
    return this.userData?.categoryId === CategoryIdsEnum.Commercial;
  }

  isIndustrial(): boolean {
    return this.userData?.categoryId === CategoryIdsEnum.Industrial;
  }

  sendGtmEvent(
    category: string,
    action: string,
    label: string | unknown[],
    flow?: string
  ) {
    this.gtm.sendCustomEvent(
      category,
      action,
      label,
      flow ?? this.getGtmFlowByCategoryId()
    );
  }

  private getGtmFlowByCategoryId(): EventFlowGtmByCategoryId | undefined {
    switch (this.userData?.categoryId) {
      case CategoryIdsEnum.HouseBuilt:
        return 'casa construida';
      case CategoryIdsEnum.NewHome:
        return 'casa em obra';
      case CategoryIdsEnum.Resident:
        return 'predios morador';
      case CategoryIdsEnum.Inhabited:
        return 'predios sindico';
      case CategoryIdsEnum.NewHousing:
        return 'predios construtora';
      case CategoryIdsEnum.Commercial:
        return 'negocios';
      case CategoryIdsEnum.Industrial:
        return 'industrias';
      default:
        return;
    }
  }

  getCategoryId(): CategoryIdsEnum | undefined {
    return this.userData?.categoryId;
  }
}
