<div class="input autocomplete" [ngClass]="{ 'input--gap': outsideLabel }">
  <label [htmlFor]="id" class="content-bold">{{
    (outsideLabel | translate)
    }}</label>
  <mat-form-field appearance="fill">
    @if (insideLabel) {
    <mat-label>{{ insideLabel | translate }}</mat-label>
    }
    <input [attr.data-testid]="dataTestId" [id]="id" [name]="name" type="text" [placeholder]="placeholder | translate"
      [attr.aria-label]="outsideLabel | translate" [formControl]="control" [matAutocomplete]="auto" (input)="onInput()"
      class="truncate" matInput #input />

    @if (control.value) {
    <button mat-icon-button matSuffix title="Limpar campo" aria-label="Limpar" class="clear-button" (click)="
        control.setValue(''); inputEvent.emit(); $event.stopPropagation()
      ">
      <mat-icon>close</mat-icon>
    </button>
    }

    <mat-autocomplete #auto="matAutocomplete">
      @for (option of filteredOptions | async; track option.place_id) {
      <mat-option style="white-space: initial; line-height: initial; padding: 0.2rem 1rem" [value]="option.description"
        (onSelectionChange)="onSelectionChange(option)">
        {{ option.description }}
      </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</div>