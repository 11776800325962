import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AsyncPipe, NgClass } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { catchError, debounceTime, Observable, of, switchMap } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import {
  MatAutocompleteModule,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GooglePlacesService } from '@shared/services';

@Component({
  selector: 'qsc-place-autocomplete',
  templateUrl: './place-autocomplete.component.html',
  styleUrls: ['./place-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatFormFieldModule,
    NgClass,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatIconModule,
    AsyncPipe,
    TranslateModule
  ],
})
export class PlaceAutocompleteComponent implements OnInit {
  @Input() control = new FormControl('');
  @Input() outsideLabel = '';
  @Input() insideLabel = '';
  @Input() id = '';
  @Input() name = '';
  @Input() placeholder = '';
  @Input() dataTestId = '';

  @Output() selectionChangeEvent = new EventEmitter<google.maps.places.AutocompletePrediction>();
  @Output() inputEvent = new EventEmitter();

  filteredOptions!: Observable<google.maps.places.AutocompletePrediction[]>;

  @ViewChild('input')
  public input: ElementRef | undefined;

  @ViewChild(MatAutocompleteTrigger)
  autoCompleteTrigger: MatAutocompleteTrigger | undefined;

  constructor(private readonly googlePlacesService: GooglePlacesService) {
    googlePlacesService.initializeService();
  }

  ngOnInit() {
    setTimeout(() => {
      this.filteredOptions = this.control.valueChanges.pipe(
        debounceTime(300),
        switchMap((value) =>
          this.googlePlacesService
            .getPlacePredictions(value ?? '')
            .pipe(catchError(() => of([])))
        )
      );
    });
  }

  public onSelectionChange(
    option: google.maps.places.AutocompletePrediction,
    closePanel = false
  ): void {
    this.selectionChangeEvent.emit(option);

    if (closePanel) {
      this.autoCompleteTrigger?.closePanel();
    }
  }

  public onInput(): void {
    this.inputEvent.emit();
  }
}
