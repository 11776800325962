import {
  equipmentsMapperForGtm,
  quantityOfUnitsForGtm,
} from '@models/gtm/flows-gtm';

export const FlowsAnalytics = {
  category: {
    menuPrincipal: 'menu principal',
    alterarSegmento: 'alterar segmento',
    qscValidarEndereco: 'QSC - validar endereco',
    qscSelecionarCategoria: 'QSC - selecionar categoria',
    qscEquipamentos: 'QSC - equipamentos',
    qscDadosPessoais: 'QSC - dados pessoais',
    qscAgendamento: 'QSC - agendamento',
    qscProtocolo: 'QSC - protocolo',
    ligaReliga: 'liga/religa',
    compartilharSolicitacao: 'compartilhar solicitacao',
    erroBackend: 'erro back',
    erroFrontend: 'erro front',
    confirmacaoEndereco: 'modal confirmacao endereco',
    verificacao: 'verificacao',
    octagora: 'octagora',
    selecionarSegmento: 'selecionar segmento',
    experiment: 'experiment',
    breadcrumb: 'breadcrumb',
    ssVazio: "ss_vazio",
    telaManutencao: 'tela_manutencao'
  },
  action: {
    logoComgas: 'logo comgas',
    refazerBuscaEndereco: 'refazer busca por endereço',
    enderecoCepConfirmado: 'endereco cep confirmado',
    validarEndereco: 'validar endereco',
    alterarEndereco: 'alterar endereco',
    encontrarCep: 'encontrar cep',
    numeroConfirmado: 'numero confirmado',
    continuar: 'continuar',
    enviaLead: 'enviaLead',
    enviaLeadTmeo: 'enviaLead TMEO',
    selecionarCategoria: 'selecionar categoria',
    dadosPessoais: 'dados pessoais',
    agendamento: 'octagora',
    confirmarSegmento: 'confirmar segmento',
    verMais: 'ver mais',
    verMenos: 'ver menos',
    voltar: 'voltar',
    novaLocalizacao: 'nova localizacao',
    copiarProtocolo: 'copiar protocolo',
    whatsapp: 'whatsapp',
    sim: 'sim',
    nao: 'nao',
    erroGenerico: 'erro generico',
    erroConsulta: 'erro consulta',
    regiaoForaArea: 'regiao fora da area',
    transbordoChat: 'transbordo chat',
    medicaoColetiva: 'medicao coletiva',
    solicitacaoExistente: 'solicitacao existente',
    enderecoLigado: 'endereco ligado',
    visualizacao: 'visualizacao',
    comgasSolucoes: 'comgas solucoes',
    perguntasFrequentes: 'perguntas frequentes',
    redirecionamento: 'redirecionamento',
    agendar: 'agendar',
    seguirTradicional: 'seguir_tradicional',
    verificarAgendamento: 'verificar_agendamento',
    consultarAgendamento: 'consultar_agendamento',
    naoSeiProtocolo: 'nao_sei_protocolo',
    recuperarProtocolo: 'recuperar_protocolo',
    escolherNovaData: 'escolher_nova_data',
    escolherNovoHorario: 'escolher_novo_horario',
    tentarNovaData: 'tentar_nova_data',
    aguardarCentral: 'aguardar_central',
    tentarNovamente: 'tentar_novamente',
    enviarNovamente: 'enviar_novamente',
    seguirSoliticacao: 'seguir_solicitacao',
    atendimentoOnline: 'atendimento_online',
    naoAgendarOnline: 'nao_agendar_online',
    naoQueroAgendar: 'nao_quero_agendar',
    naoQueroReagendar: 'nao_quero_reagendar',
    confirmarDados: 'confirmar_dados',
    concluirSoliticacao: 'concluir_solicitacao',
    consultarSolicitacao: 'consultar_solicitacao',
    sair: 'sair',
    voltarHome: 'voltar_home',
    voltarDados: 'voltar_dados',
    iniciarReagedamento: 'iniciar_reagendamento',
    iniciarCancelamento: 'iniciar_cancelamento',
    cancelarAgendamento: 'cancelar_agendamento',
    naoCancelar: 'nao_cancelar',
    reagendarAtendimento: 'reagendar_atendimento',
    naoAlterarData: 'nao_alterar_data',
    telaErro: 'tela_erro',
    modalErro: 'modal_erro',
    modalErroTentar: 'modal_erro_tentar',
    modalErroSair: 'modal_erro_sair',
    modalAgendamento: 'modal_agendamento',
    modalLigaReliga: 'modal_liga_religa',
    modalConsultaAgendamento: 'modal_consulta_agendamento',
    selecionarComplemento: 'selecionar complemento',
    naoEncontreiComplemento: 'nao encontrei complemento',
    inserirComplemento: 'inserir complemento',
    view: 'view',
    carrouselAgendamento: 'carrossel_agendamento',
    selecionarDia: 'selecionar_dia',
    selecionarHorario: 'selecionar_horario',
    contratarAgora: 'contratar_agora',
    telefoneConfirmado: 'telefone confirmado',
    adicionarCalendario: 'adicionar calendario',
    seguirFluxo: 'seguir fluxo',
    enviaCase: 'enviaCase',
    nomeConfirmado: 'nome confirmado',
    emailConfirmado: 'email confirmado',
    cpfCnpjConfirmado: 'cpf cnpj confirmado',
    razaoSocialConfirmada: 'razao social confirmada',
    possuiComplemento: 'possui complemento',
    tooltipComplemento: 'tooltip complemento',
    complementoConfirmado: 'complemento confirmado',
    referenciaConfirmada: 'referencia confirmada',
    jaSouCliente: 'ja sou cliente'
  },
  label: {
    clique: 'clique',
    buscarEndereco: 'buscar endereco',
    confirmarEndereco: 'confirmar endereco',
    alterarEndereco: 'alterar endereco',
    refazerBusca: 'refazer busca',
    casaEmObra: 'casa em obra',
    casaConstruida: 'casa construida',
    prediosMorador: 'predios morador',
    prediosSindico: 'predios sindico',
    prediosConstrutora: 'predios construtora',
    sucesso: 'sucesso',
    modalConfirmacaoEndereco: 'modal confirmacao endereco',
    quantidade: quantityOfUnitsForGtm,
    equipamentosMapper: equipmentsMapperForGtm,
    visualizacao: 'visualizacao',
    iniciarChat: 'iniciar chat',
    acompanhar: 'acompanhar',
    ligaReliga: 'liga_religa',
    mutirao: 'mutirao',
    individualizacao: 'individualizacao',
    modalAgendamento: 'modal_agendamento',
    modalHorario: 'modal_horario',
    modalCancelamento: 'modal_cancelamento',
    maisDeUmaUnidade: 'mais de uma unidade',
    sim: 'sim',
    nao: 'nao',
    residencias: 'residencias',
    predios: 'predios',
    negocios: 'negocios',
    industrias: 'industrias',
    videoChamada: 'videochamada',
    sair: 'sair',
    complementoSelecionado: 'complemento selecionado',
    naoEncontrouComplemento: 'nao encontrou complemento',
    control: 'control',
    variant: 'variant',
    google: 'google',
    outlook: 'outlook',
    apple: 'apple',
    ical: 'ical',
    ms365: 'ms365',
    msteams: 'msteams',
    yahoo: 'yahoo',
    ramalVerdadeiro: 'ramal verdadeiro',
    residenciaUnificada: 'resid_uni',
    voltar: 'voltar',
    abrir: 'abrir',
  },
};
