@if (elementType === 'a') { @if(routerLink) {
<a
  [routerLink]="routerLink"
  [attr.aria-disabled]="disabled"
  (click)="click($event)"
  [ngClass]="['button', getVariant()]"
>
  <ng-container *ngTemplateOutlet="contentTemplate"> </ng-container>
</a>
} @else {
<a
  [attr.href]="href"
  [attr.target]="target ?? '_self'"
  [attr.aria-disabled]="disabled"
  (click)="click($event)"
  [ngClass]="['button', getVariant()]"
>
  <ng-container *ngTemplateOutlet="contentTemplate"> </ng-container>
</a>
} } @else {
<button
  [disabled]="disabled"
  [type]="buttonType"
  (click)="click($event)"
  [ngClass]="['button', getVariant()]"
>
  <ng-container *ngTemplateOutlet="contentTemplate"> </ng-container>
</button>
}

<ng-template #contentTemplate>
  <div class="button__content">
    <div class="button__icon">
      @if (icon && iconBefore) {
      <qsc-svg-icon
        class="left m-1 align-middle"
        width="1.8rem"
        height="1.8rem"
        [icon]="icon"
      ></qsc-svg-icon>
      }
    </div>
    @if (iconClass.length && iconBefore) {
    <div class="button__icon">
      <i aria-hidden="true" [class]="'left ' + getIconClass('before')"></i>
    </div>
    }
    <div class="button__text">
      <span [innerHTML]="buttonText | translate"> </span>
    </div>
    @if (iconClass.length && iconAfter) {
    <div class="button__icon">
      <i aria-hidden="true" [class]="'right ' + getIconClass('after')"></i>
    </div>
    }
  </div>
</ng-template>
