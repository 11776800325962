<section class="advantages container px-3 container-fhd">
  <div class="row row-cols-1 row-cols-lg-2 g-4">
    <div class="col col-lg-5 col-xxl-6">
      <div class="advantages__title-image">
        <h2
          class="advantages__title"
          [innerHTML]="data.title.mobile | translate"
        ></h2>
        <div class="advantages__image">
          <qsc-picture
            [breakpoints]="data.image.breakpoints"
            [alt]="data.image.alt"
            [title]="data.image.title"
            [fullWidth]="true"
            [fullHeight]="true"
            [cover]="true"
          ></qsc-picture>
        </div>
      </div>
    </div>

    <div class="col col-lg-7 col-xxl-6">
      <div class="d-none d-lg-block">
        <mat-accordion class="advantages__items" [multi]="true">
          @for (item of data.items; track item.title) {
          <mat-expansion-panel
            hideToggle
            [expanded]="true"
            (opened)="onOpened($index)"
            (closed)="onClosed($index)"
            [disabled]="true"
            class="advantages__item"
            [ngClass]="{
              'advantages__item--desktop': true
            }"
          >
            <span
              class="advantages__item-icon"
              [ngClass]="{
                'advantages__item-icon--active': panelOpenId === $index,

              }"
            >
              <i aria-hidden="true" [class]="item.icon"></i>
            </span>
            <mat-expansion-panel-header class="advantages__item-header">
              <mat-panel-title class="advantages__item-title">
                <div class="advantages__item-title-container">
                  <h3
                    class="advantages__item-title-text mb-0"
                    [innerHTML]="item.title | translate"
                  ></h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div
              class="advantages__item-description"
              [innerHTML]="item.description | translate"
            ></div>
          </mat-expansion-panel>
          }
        </mat-accordion>
      </div>

      <div class="d-lg-none">
        <mat-accordion class="advantages__items" [multi]="false">
          @for (item of data.items; track item.title) {
          <mat-expansion-panel
            hideToggle
            [expanded]="panelOpenId === $index"
            (opened)="onOpened($index)"
            (closed)="onClosed($index)"
            [disabled]="false"
            class="advantages__item"
            [ngClass]="{
              'advantages__item--active': panelOpenId === $index
            }"
          >
            <mat-expansion-panel-header class="advantages__item-header">
              <mat-panel-title class="advantages__item-title">
                <div class="advantages__item-title-container">
                  <span
                    class="advantages__item-icon"
                    [ngClass]="{
                      'advantages__item-icon--active': panelOpenId === $index
                    }"
                  >
                    <i aria-hidden="true" [class]="item.icon"></i>
                  </span>
                  <h3
                    class="advantages__item-title-text mb-0"
                    [innerHTML]="item.title | translate"
                  ></h3>
                </div>
                <span
                  class="advantages__item-toggle-icon"
                  [ngClass]="{
                    'advantages__item-toggle-icon--rotate':
                      panelOpenId === $index
                  }"
                >
                  <i aria-hidden="true" class="icon-sinal_somar"></i
                ></span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div
              class="advantages__item-description"
              [innerHTML]="item.description | translate"
            ></div>
          </mat-expansion-panel>
          }
        </mat-accordion>
      </div>
    </div>
  </div>
</section>
