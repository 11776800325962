<header id="header" class="header" [ngClass]="{ 'hide-search-bar': isFaqPage }">
  <div class="position-fixed z-50 w-100 bg-white">
    <div class="header__switcher container container-fhd px-3">
      <qsc-theme-switcher class="align-self-end"></qsc-theme-switcher>
    </div>
    <div class="header__background">
      <div class="header__container container container-fhd px-3">
        <div class="d-flex align-items-center justify-content-between w-100 d-lg-none">
          <div class="header__menu-container me-sm-3">
            <qsc-hamburger-menu />
          </div>

          <a class="header__logo" routerLink="/" (click)="onClickLogo()">
            <img
              class="d-lg-none d-xl-inline"
              [src]="'assets/img/svg/logo/logo-white.svg' | fileHash"
              [title]="'@HOMEPAGE' | translate"
              [alt]="'@HOMEPAGE' | translate"
              loading="lazy"
              />
              <i class="icon-logo_comgas d-none d-lg-inline d-xl-none"></i>
            </a>

            <div class="d-flex flex-row-reverse align-items-center gap-3">
              <button
                class="emergency-btn d-lg-none"
                (click)="openEmergencies()"
                type="button"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Emergencia"
                >
                <img
                  [src]="'assets/img/svg/icon-cg-alerta.svg' | fileHash"
                  alt="Emergencia"
                  loading="lazy"
                  />
                </button>

                @if (!isFaqPage) {
                  <div class="search d-none d-sm-inline-block d-lg-none">
                    <qsc-search
                      [id]="'desktop-search'"
                      name="search"
                      [options]="searchServicesData.services"
                      [placeholder]="'@HEADER-BUSCA-PLACEHOLDER' | translate"
                      (selectionChangeEvent)="onSelectedService($event)"
                      #searchInput
                    ></qsc-search>
                  </div>
                }
              </div>
            </div>

            <nav class="header__nav">
              <div class="w-100 d-flex align-items-center gap-4">
                <a
                  class="header__logo d-none d-lg-inline-block"
                  routerLink="/"
                  (click)="onClickLogo()"
                  >
                  <img
                    class="d-lg-none d-xl-inline"
                    [src]="'assets/img/svg/logo/logo-white.svg' | fileHash"
                    [title]="'@HOMEPAGE' | translate"
                    [alt]="'@HOMEPAGE' | translate"
                    loading="lazy"
                    />
                    <i class="icon-logo_comgas d-none d-lg-inline d-xl-none"></i>
                  </a>

                  @if (!isFaqPage) {
                    <div class="d-sm-none d-lg-inline-block w-100 search">
                      <qsc-search
                        [id]="'mobile-search'"
                        name="search"
                        [options]="searchServicesData.services"
                        [placeholder]="'@HEADER-BUSCA-PLACEHOLDER' | translate"
                        (selectionChangeEvent)="onSelectedService($event)"
                        #searchInput
                      ></qsc-search>
                    </div>
                  }
                </div>

                <ul class="d-flex align-items-center">
                  <li class="segment-links">
                    @for (link of headerData.links; track link.id) {
                      @if (link.url.startsWith('/')) {
                        <a
                          [routerLink]="link.url"
                          class="segment-link text-nowrap"
                          [innerHTML]="link.label | translate"
                          (click)="onSegmentClick(link.id)"
                  [ngClass]="{
                    'highlighted-link': checkCurrentPageByUrl(link.url)
                  }"
                        ></a>
                      }
                      @if (!link.url.startsWith('/')) {
                        <a
                          [href]="link.url"
                          target="_blank"
                          class="segment-link text-nowrap"
                          [innerHTML]="link.label | translate"
                          (click)="onSegmentClick(link.id)"
                  [ngClass]="{
                    'highlighted-link': checkCurrentPageByUrl(link.url)
                  }"
                        ></a>
                      }
                    }
                  </li>
                  <li class="d-none d-lg-flex flex-items-lg-center gap-lg-4">
                    <button
                      class="emergency-btn"
                      (click)="openEmergencies()"
                      type="button"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Emergencia"
                      >
                      <img
                        [src]="'assets/img/svg/icon-cg-alerta.svg' | fileHash"
                        alt="Emergencia"
                        loading="lazy"
                        />
                      </button>
                      <button
                        class="button--green"
                        (click)="contractNow()"
                        type="button"
                        >
                        <div class="d-flex justify-content-center">
                          <span
                            class="d-lg-none"
                    [innerHTML]="
                      isSwimmingPoolPage
                        ? ('@SPEAK-TO-CONSULTANT' | translate)
                        : ('@HEADER-CONTRATAR-GAS-ENCANADO-MOBILE' | translate)
                    "
                          ></span>
                          <span
                            class="text-nowrap d-none d-lg-block"
                    [innerHTML]="
                      isSwimmingPoolPage
                        ? ('@SPEAK-TO-CONSULTANT' | translate)
                        : ('@HEADER-CONTRATAR-GAS-ENCANADO' | translate)
                    "
                          ></span>
                        </div>
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </header>
