import { Inject, Injectable } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { environment } from '@env';
import { DOCUMENT } from '@angular/common';
import { OpenedRequestModalComponent } from '@modules/address-validation/components/opened-request-modal/opened-request-modal.component';
import { take } from 'rxjs';
import { ChatService } from '../chat/chat.service';

@Injectable({
  providedIn: 'root',
})
export class OpenedRequestModalService {
  bsModalOpenedRequest: any;
  window: any;

  constructor(
    private readonly bsModalService: BsModalService,
    private readonly chatService: ChatService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.window = this.document.defaultView;
  }

  openOpenedRequestModal(): void {
    const modalOptions: ModalOptions = {
      class: 'opened-request-modal modal-dialog-centered',
    };

    this.bsModalOpenedRequest = this.bsModalService.show(
      OpenedRequestModalComponent,
      modalOptions
    );

    this.bsModalOpenedRequest.onHide
      .pipe(take(1))
      .subscribe(() => {
        if (this.bsModalOpenedRequest.content.trackRequest) {
          this.goToRequestTracking();
          return;
        }
        if (this.bsModalOpenedRequest.content.openChat) {
          this.chatService.openChat();
        }
      });
  }

  goToRequestTracking(): void {
    const url =
      environment.endpoints.comgasVirtual + 'acompanhamentoSolicitacao';
    this.window.open(url, '_self');
  }
}
