<div id="quick-access" class="quick-access py-3 py-xl-4">
  <div class="container-lg px-0 px-lg-3 container-fhd">
    <swiper [config]="config">
      @for (link of quickLinks; track link.name) {
      <ng-template swiperSlide>
        <a
          class="quick-access__item"
          tabindex="0"
          [attr.data-testid]="link.name | translate"
          [qscAdvancedRouterLink]="getChannelUrl(link.url, link.channel)"
          [qscEventTrackerCategory]="homeAnalytics.category.acessoRapido"
          [qscEventTrackerAction]="link.actionEvent || link.name | translate"
          [qscEventTrackerLabel]="homeAnalytics.label.clique"
        >
          <div class="quick-access__item-label">
            <i aria-hidden="true" [class]="link.iconClass"></i>
            <p [innerHTML]="link.name | translate"></p>
          </div>
        </a>
      </ng-template>
      }
    </swiper>
  </div>
</div>
