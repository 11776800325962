import { fromUser } from '@core/index';
import {
  IBuscaEnderecoUnificadaInput,
  IVerificaInstalacaoInput,
} from '@modules/address-validation/models/address-validation';
import { getProfile } from '@models/index';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ProfileService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class AddressMapper {
  userData?: fromUser.IUser | null;

  constructor(
    private readonly store: Store,
    private readonly profileService: ProfileService
  ) {
    this.store
      .select(fromUser.selectUserData)
      .subscribe((data) => {
        this.userData = data;
      });
  }

  createVerifiesInstallationInput(): IVerificaInstalacaoInput {
    const unifiedAddress = this.userData?.unifiedAddress
      ? this.userData.unifiedAddress
      : this.userData?.address;

    return {
      cep: unifiedAddress?.cep ?? '',
      logradouro: unifiedAddress?.logradouro ?? '',
      numero: this.userData?.number ?? '',
      complemento:
        this.userData?.complement?.complemento ??
        this.userData?.optionalComplement ??
        '',
      suplemento: this.userData?.complement?.suplemento ?? '',
      apartamento: this.userData?.apartment ?? '',
      cidade: unifiedAddress?.cidade ?? '',
      estado: unifiedAddress?.estado ?? '',
      bairro: unifiedAddress?.bairro ?? '',
      perfil: getProfile(this.profileService),
      idLogradouro: this.userData?.address?.streetcode ?? '',
      nome: this.userData?.name ?? '',
      telefone: this.userData?.phoneNumber ?? '',
    };
  }

  createAddressData(): any {
    const unifiedAddress = this.userData?.unifiedAddress
      ? this.userData?.unifiedAddress
      : this.userData?.address;

    return {
      logradouro: unifiedAddress?.logradouro,
      bairro: unifiedAddress?.bairro,
      cep: unifiedAddress?.cep,
      cidade: unifiedAddress?.cidade,
      numero: parseInt(this.userData?.number as string),
      estado: unifiedAddress?.estado,
      complemento:
        this.userData?.complement?.complemento ??
        this.userData?.optionalComplement ??
        '',
    };
  }

  createUnifiedAddressBody(): IBuscaEnderecoUnificadaInput {
    return {
      nome: this.userData?.name ?? '',
      telefone: this.userData?.phoneNumber ?? '',
      dadosEndereco: {
        cep: this.userData?.address?.cep ?? '',
        logradouro: this.userData?.address?.logradouro ?? '',
        numero: this.userData?.number ?? '',
        bairro: this.userData?.address?.bairro ?? '',
        cidade: this.userData?.address?.cidade ?? '',
        estado: this.userData?.address?.estado ?? '',
        apartamento: this.userData?.apartment ?? '',
        complemento:
          this.userData?.complement?.complemento ??
          this.userData?.optionalComplement ??
          '',
        suplemento: this.userData?.complement?.suplemento ?? '',
        idEndereco: this.userData?.address?.streetcode ?? '',
      },
    };
  }
}
