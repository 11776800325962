<section class="about">
  <div class="bg-color-light-3">
    <div class="about__cards-container container px-3 container-fhd">
      <h2
        class="about__title"
        [innerHTML]="'@HOME-ABOUT-TITLE' | translate"
      ></h2>
      <div class="row gy-3">
        @for (card of data.cards; track card.title) {
        <div class="col-12 col-md-6">
          <qsc-card-more-about [data]="card"></qsc-card-more-about>
        </div>
        }
      </div>
    </div>
  </div>

  <div class="bg-white">
    <h2
      class="about__steps-title gradient-text container px-3 container-fhd"
      [innerHTML]="'@HOME-ABOUT-STEPS-TITLE' | translate"
    ></h2>
    <div class="about__content">
      <div
        class="about__swiper-container container-xxl px-0 px-xxl-3 container-fhd"
      >
        <swiper [config]="swiperConfig">
          <ng-template swiperSlide
            ><div class="about__steps">
              <img
                class="d-none d-xl-block"
                [src]="data.stepsImages.desktop | fileHash"
                [alt]="'@HOME-ABOUT-STEPS-TITLE' | translate"
              />
              <img
                class="d-xl-none"
                [src]="data.stepsImages.mobile | fileHash"
                [alt]="'@HOME-ABOUT-STEPS-TITLE' | translate"
              />
              @for (step of data.steps; track step) {
              <div [class]="'about__step ' + 'about__step--' + ($index + 1)">
                <p
                  class="about__step-title"
                  [innerHTML]="'0' + ($index + 1)"
                ></p>
                <p
                  class="about__step-description"
                  [innerHTML]="step | translate"
                ></p>
              </div>
              }
            </div></ng-template
          >
        </swiper>

        <div class="about__obs container px-3">
          <img
            [src]="'assets/img/svg/icon-attention-sign.svg' | fileHash"
            alt=""
          />
          <p class="text-md-center" [innerHTML]="data.obs | translate"></p>
        </div>
      </div>

      <div class="about__numbers container px-3 container-fhd">
        @for (number of data.numbers; track number.value) {
        <div class="about__number">
          <qsc-animated-number
            [startNumber]="'0'"
            [endNumber]="number.value | translate"
            [isPercentage]="!!number.isPercentage"
          ></qsc-animated-number>
          <div class="about__number-legend">
            <p [innerHTML]="number.legend | translate"></p>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</section>
