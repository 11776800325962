import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private renderer: Renderer2;

  constructor(private readonly rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  addModalToContainer(modalContainer: string, modalSelector: string): void {
    setTimeout(() => {
      const modalElement = document.querySelectorAll('.modal');
      const container = document.getElementById(modalContainer);

      modalElement.forEach((element) => {
        const hasModal = element?.querySelector(modalSelector);

        if (modalElement && container && hasModal) {
          this.renderer.appendChild(container, element);
        }
      });

      this.enablePageScroll();
    }, 0);
  }

  enablePageScroll() {
    setTimeout(() => {
      this.renderer.removeClass(document.body, 'modal-open');
      document.body.style.overflowY = 'auto';
    });
  }
}
