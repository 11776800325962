<div class="headline-banner">
  <div class="headline-banner__gradient" [ngClass]="chosenVariant"></div>
  <div class="headline-banner__background">
    <qsc-picture
      [breakpoints]="image?.breakpoints"
      [alt]="image?.alt"
      [title]="image?.title"
      [fullHeight]="true"
      [fullWidth]="true"
      [cover]="true"
    ></qsc-picture>
  </div>

  <div class="headline-banner__content container px-3 container-fhd">
    <h1 class="mb-0" [innerHTML]="title | translate"></h1>
    @if (description) {
      <p
        class="mb-0 text-light"
        [innerHTML]="description | translate"
      ></p>
    }

    @if (showSearch) {
      <div class="headline-banner__search">
        <qsc-search
          class="w-100 max-w-search"
          [id]="'faq-search'"
          name="search"
          [options]="searchServicesData.services"
          [placeholder]="'@HEADER-BUSCA-PLACEHOLDER' | translate"
          (selectionChangeEvent)="onSelectedService($event)"
          #searchInput
        ></qsc-search>
      </div>
    }
  </div>
</div>
