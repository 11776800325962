@if (showChatMessage$ | async) {
  <div
    class="chat-message p-2"
    id="chat-message"
    >
    <button
      class="btn-reset chat-message__btn-close"
      (click)="hideChatMessage()"
      >
      <i aria-hidden="true" class="icon-sinal_multiplicar_x_fechar"></i>
    </button>
    <span [innerHTML]="'@CHAT-MESSAGE-DEFAULT' | translate"></span>
  </div>
}
