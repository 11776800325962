import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { Observable, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { WindowRefService, BackToTopService } from '@shared/services';
import { FooterAnalytics } from '@models/analytics';
import { EventTrackerDirective } from '@shared/directives';
import { UiService } from '@core/index';

@UntilDestroy()
@Component({
  selector: 'qsc-back-to-top',
  standalone: true,
  imports: [CommonModule, EventTrackerDirective, TranslateModule],
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.scss'],
})
export class BackToTopComponent implements OnInit {
  showBackToTop$!: Observable<boolean>;

  constructor(
    private readonly backToTop: BackToTopService,
    private readonly windowRef: WindowRefService,
    private readonly uiService: UiService,
    private readonly ngZone: NgZone,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.showBackToTop$ = this.backToTop.getBackToTopStatus();
  }

  ngOnInit(): void {
    this.backToTop.resetBackToTopStatus();
    this.subscribeToWindowScroll();
  }

  get footerAnalytics() {
    return FooterAnalytics;
  }

  private subscribeToWindowScroll(): void {
    if (!this.windowRef.nativeWindow) return;
    this.ngZone.runOutsideAngular(() => {
      fromEvent(this.windowRef.nativeWindow, 'scroll')
        .pipe(
          map(() =>
            this.uiService.isMatchedBreakpoint('xl')
              ? !this.isAtPageBottom() && this.getYPosition() > 100
              : this.getYPosition() > 100
          ),
          distinctUntilChanged(),
          untilDestroyed(this)
        )
        .subscribe((windowScrolled) => {
          this.ngZone.run(() =>
            this.backToTop.updateBackToTopStatus(windowScrolled)
          );
        });
    });
  }

  private isAtPageBottom(): boolean {
    const yPos = this.getYPosition();
    const windowHeight = this.windowRef.nativeWindow.innerHeight;
    const documentHeight = this.document.documentElement.scrollHeight;
    const bottomMargin = 100;
    return yPos + windowHeight >= documentHeight - bottomMargin;
  }

  private getYPosition() {
    return (
      this.windowRef.nativeWindow.scrollY ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop
    );
  }

  scrollToTop() {
    this.windowRef.nativeWindow.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}
