import { Injectable } from '@angular/core';
import {
  IEnviaLeadInput,
  IEnviaLeadOutput,
  IEnviaLeadParametrosMidia,
  IObterLeadInput,
  IObterLeadOutput,
  IRecuperarProtocoloInput,
  IRecuperarProtocoloOutput,
} from '@models/lead/lead';
import { environment } from '@env';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GoogleTagManagerService } from '@core/google-tag-manager/google-tag-manager.service';
import { IApiResponse } from '@models/api/api-response';
import { Store } from '@ngrx/store';
import { fromUser, userActions } from '@core/store';

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  private readonly _backEndPoint = environment.endpoints.backEndPoint;
  private leadInput?: IEnviaLeadInput;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store,
    private readonly gtm: GoogleTagManagerService
  ) {
    this.store
      .select(fromUser.selectUserData)
      .subscribe((data) => {
        this.leadInput = data?.leadInput;
      });
  }

  getMediaParameters(): IEnviaLeadParametrosMidia {
    return this.gtm.getMediaParameters();
  }

  saveInput(input: IEnviaLeadInput): void {
    this.store.dispatch(
      userActions.updateUser({
        user: {
          leadInput: input,
        },
      })
    );
  }

  loadInput(): IEnviaLeadInput | undefined {
    return this.leadInput;
  }

  removeInput(): void {
    this.store.dispatch(
      userActions.updateUser({
        user: {
          leadInput: undefined,
        },
      })
    );
  }

  getLead(input: IObterLeadInput) {
    const url = this._backEndPoint.concat('/leads/obter-lead');
    return this.http.get<IApiResponse<IObterLeadOutput>>(url, {
      params: { ...input },
    });
  }

  recoveryProtocol(input: IRecuperarProtocoloInput) {
    const url = this._backEndPoint.concat('/leads/recuperar-protocolo');
    return this.http.post<IApiResponse<IRecuperarProtocoloOutput>>(url, input);
  }

  postSendLead(input: IEnviaLeadInput): Observable<IEnviaLeadOutput> {
    const url = this._backEndPoint.concat('/leads/envia-lead');
    return this.http.post<IEnviaLeadOutput>(url, input);
  }
}
