import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { IMarkerData } from '@shared/services/google-maps/marker/marker-data';
import { GoogleMapsService } from '@shared/services/google-maps/google-maps.service';
import {
  GoogleMapsModalService,
  WaypointService,
  WaypointUtilsService,
} from '@shared/services';
import { getDistanceUnit, getTimeUnit } from '@shared/utils';
import { Subject, take, takeUntil } from 'rxjs';
import { MapActionModalEnum } from './map-action-modal/map-action-modal.component';
import { MapNotificationModalEnum } from './map-notification-modal/map-notification-modal.component';

@Component({
  selector: 'qsc-google-maps',
  standalone: true,
  imports: [CommonModule, GoogleMapsModule],
  providers: [TitleCasePipe],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss'],
})
export class GoogleMapsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: IMarkerData[] = [];
  @Input() origin = '';
  @Input() destination = '';
  @Input() showHighFlowOnly = false;
  @Input() showOpenedStationsOnly = false;
  @Input() distanceKm = 0;
  @Input() searchStationType?: 'route' | 'area';

  @Output() routeCreatedEvent = new EventEmitter<{
    duration: string;
    distance: string;
  }>();
  @Output() routeErrorEvent = new EventEmitter<void>();
  @Output() routeErrorConfirmEvent = new EventEmitter<void>();

  private readonly destroy$ = new Subject<void>();
  private originOrDestinationChanged = false;

  constructor(
    private readonly googleMapsService: GoogleMapsService,
    private readonly googleMapsModalService: GoogleMapsModalService,
    private readonly waypointService: WaypointService,
    private readonly waypointUtilsService: WaypointUtilsService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.googleMapsService.initMapAsync('map');

    this.waypointService.waypoints$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async () => {
        if (this.origin && this.destination) {
          this.googleMapsService
            .createRoute()
            .then((result: google.maps.DirectionsResult | null) => {
              this.waypointUtilsService.addWaypointContainer(this.data);
              this.sendDurationAndDistance(result);
            }).catch(() => {
              this.routeErrorEvent.emit();
              this.openActionErroModal();
            });
        }
      });

    await this.googleMapsService
      .updateMarkersToRoute(
        this.origin,
        this.destination,
        this.showOpenedStationsOnly,
        this.showHighFlowOnly
      )
      .then(() => {
        this.waypointService.waypoints$
          .pipe(takeUntil(this.destroy$))
          .subscribe(async () => {
            if (this.origin && this.destination) {
              if (this.originOrDestinationChanged) {
                this.originOrDestinationChanged = false;
                return;
              }

              this.googleMapsService
                .createRoute()
                .then((result: google.maps.DirectionsResult | null) => {
                  this.waypointUtilsService.addWaypointContainer(this.data);
                  this.sendDurationAndDistance(result);
                }).catch(() => {
                  this.routeErrorEvent.emit();
                  this.openActionErroModal();
                });
            }
          });
      });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (!this.googleMapsService.map) return;

    this.updateGoogleMapsServiceData();

    this.searchStationType =
      changes['searchStationType']?.currentValue ?? this.searchStationType;

    if (this.searchStationType === 'route') {
      await this.onRoute(changes);
      return;
    }

    if (this.searchStationType === 'area') {
      await this.onSearchArea(changes);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async onRoute(changes: SimpleChanges) {
    if (!this.shouldProcessRoute()) return;

    if (this.originOrDestinationHasChanged(changes)) {
      this.originOrDestinationChanged = true;

      try {
        await this.updateMarkersToRoute();

        if (!this.origin || !this.destination) {
          this.googleMapsService.clearRoute();
          return;
        }

        await this.createRoute(changes);
      } catch {
        this.openActionErroModal();
      }
    }
  }

  async onSearchArea(changes: SimpleChanges) {
    if (!this.shouldProcessSearchArea()) return;

    if (this.originOrDistanceHasChanged(changes)) {
      this.updateMarkersToSearchArea(changes);
    }
  }

  private shouldProcessRoute(): boolean {
    if (!this.origin || !this.destination) {
      this.googleMapsService.clearAll();
      return false;
    }
    return this.hasVisibleLocations();
  }

  private shouldProcessSearchArea(): boolean {
    if (!this.origin) {
      this.googleMapsService.clearAll();
      return false;
    }

    return this.hasVisibleLocations();
  }

  private originOrDestinationHasChanged(changes: SimpleChanges): boolean {
    return Boolean(
      changes['origin'] ||
      changes['destination'] ||
      changes['showHighFlowOnly'] ||
      changes['showOpenedStationsOnly']
    );
  }

  private originOrDistanceHasChanged(changes: SimpleChanges): boolean {
    return Boolean(
      changes['origin'] ||
      changes['distanceKm'] ||
      changes['showHighFlowOnly'] ||
      changes['showOpenedStationsOnly']
    );
  }

  private async updateMarkersToRoute(): Promise<void> {
    await this.googleMapsService.updateMarkersToRoute(
      this.origin,
      this.destination,
      this.showOpenedStationsOnly,
      this.showHighFlowOnly
    );
    this.googleMapsService.clearWaypoints();
  }

  private async updateMarkersToSearchArea(
    changes: SimpleChanges
  ): Promise<void> {
    await this.googleMapsService.updateMarkersToSearchArea(
      this.origin,
      this.distanceKm,
      this.showOpenedStationsOnly,
      this.showHighFlowOnly,
      changes['showOpenedStationsOnly']?.currentValue
    );
  }

  private async createRoute(changes: SimpleChanges): Promise<void> {
    const highFlowChanged = !!changes['showHighFlowOnly'];
    const openedStationsChanged = !!changes['showOpenedStationsOnly'];

    const result = await this.googleMapsService.createRoute();

    if (!highFlowChanged && !openedStationsChanged) {
      (document.activeElement as HTMLElement).blur();
      this.googleMapsModalService.openNotificationModal(
        MapNotificationModalEnum.RouteCreated
      );
    }

    (document.activeElement as HTMLElement).blur();
    this.sendDurationAndDistance(result);
  }

  private hasVisibleLocations(): boolean {
    return this.data.some((location) => location.visible);
  }

  private updateGoogleMapsServiceData(): void {
    this.googleMapsService.data = this.data;
  }

  private openActionErroModal(): void {
    (document.activeElement as HTMLElement).blur();
    this.googleMapsService.clearAll();
    const modal = this.googleMapsModalService.openActionModal(
      MapActionModalEnum.Review
    );
    modal.onHide?.pipe(take(1)).subscribe(() => {
      if (modal.content.confirm) {
        this.routeErrorConfirmEvent.emit();
      }
    });
  }

  private sendDurationAndDistance(
    result: google.maps.DirectionsResult | null
  ): void {
    let duration = 0;
    let distance = 0;

    result?.routes[0]?.legs.forEach((leg) => {
      duration += leg?.duration?.value ?? 0;
      distance += leg.distance?.value ?? 0;
    });

    this.routeCreatedEvent.emit({
      duration: `${getTimeUnit(duration)}`,
      distance: `${getDistanceUnit(distance)}`,
    });
  }
}
