import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  Input,
  ViewChild,
} from '@angular/core';
import { debounceTime, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { DOCUMENT, NgClass } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'qsc-swiper-image',
  standalone: true,
  imports: [NgClass],
  templateUrl: './swiper-image.component.html',
  styleUrl: './swiper-image.component.scss',
})
export class SwiperImageComponent implements AfterViewInit {
  @Input() imageSrc = '';
  @Input() slideWidth?: number;
  @Input() spacingX?: number;
  @ViewChild('swiperContainer', { static: true }) swiperContainer!: ElementRef;
  @ViewChild('swiperImage', { static: true }) swiperImage!: ElementRef;
  window = inject(DOCUMENT).defaultView;

  slideIndex = 1;
  stops: number[] = [];
  private containerWidth = 0;
  private contentWidth = 0;
  private numBullets = 0;
  private MIN_BULLETS = 2;

  ngAfterViewInit(): void {
    this.setupImageLoadListener();

    fromEvent<Event>(this.swiperContainer.nativeElement, 'scroll')
      .pipe(
        debounceTime(200),
        map((event: Event) => {
          const target = event.target as HTMLElement;
          return target.scrollLeft;
        }),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe((scrollLeft) => {
        this.updateSlideIndex(scrollLeft);
      });

    if (this.window) {
      fromEvent(this.window, 'resize')
        .pipe(debounceTime(200), untilDestroyed(this))
        .subscribe(() => {
          this.calculateAndReposition();
        });
    }
  }

  private setupImageLoadListener(): void {
    this.swiperImage.nativeElement.onload = () => {
      setTimeout(() => {
        this.calculateAndReposition();
      }, 0);
    };
  }

  private calculateAndReposition(): void {
    this.containerWidth = this.swiperContainer.nativeElement.clientWidth;
    this.contentWidth = this.swiperImage.nativeElement.width;

    const slideWidthToUse = this.slideWidth ?? this.containerWidth;

    this.swiperContainer.nativeElement.scrollLeft = 0;

    const spacing = this.spacingX ? this.spacingX * 2 : 32;
    this.calculateStops(slideWidthToUse - spacing);
  }

  private calculateStops(slideWidth: number): void {
    if (this.containerWidth >= this.contentWidth) {
      this.numBullets = 0;
      this.stops = [];
    } else {
      this.numBullets = Math.max(
        Math.floor(this.contentWidth / slideWidth),
        this.MIN_BULLETS
      );

      this.stops = [];
      for (let i = 0; i < this.numBullets; i++) {
        const stop =
          (i * slideWidth) / (this.contentWidth - this.containerWidth);
        this.stops.push(stop);
      }
    }
  }

  private updateSlideIndex(scrollLeft: number): void {
    const maxScrollLeft = this.contentWidth - this.containerWidth;
    const scrollPercent = scrollLeft / maxScrollLeft;

    if (scrollLeft >= maxScrollLeft - 1) {
      this.slideIndex = this.stops.length;
    } else {
      const currentSlide = this.stops.findIndex((s) => scrollPercent <= s);
      this.slideIndex = currentSlide !== -1 ? currentSlide + 1 : 1;
    }
  }

  public scrollToSlide(index: number): void {
    if (index > 0 && index <= this.stops.length) {
      const stopPoint = this.stops[index - 1];
      const scrollToPosition =
        stopPoint * (this.contentWidth - this.containerWidth);
      this.swiperContainer.nativeElement.scrollTo({
        left: scrollToPosition,
        behavior: 'smooth',
      });
    }
  }
}
