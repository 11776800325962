import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ISegment } from '@modules/home/models/home';
import { SegmentCardComponent } from '../segment-card/segment-card.component';
import SwiperCore, { SwiperOptions, Pagination, Navigation } from 'swiper';
import { SwiperModule } from 'swiper/angular';

SwiperCore.use([Pagination, Navigation]);

@Component({
  selector: 'qsc-segments',
  templateUrl: './segments.component.html',
  styleUrls: ['./segments.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass, SegmentCardComponent, SwiperModule, TranslateModule],
})
export class SegmentsComponent {
  @Input() data: ISegment[] = [];
  @Input() title = '@HOME-SEGMENTOS-CTA-TEXTO';
  @Input() gridItemClass = '';

  swiperConfig: SwiperOptions = {
    pagination: {
      clickable: true,
    },
    navigation: true,
    spaceBetween: 24,
    slidesPerView: 2.5,
    slidesPerGroup: 2,
    breakpoints: {
      1200: {
        slidesPerView: 3.5,
      },
      1400: {
        slidesPerView: 4,
        pagination: false,
        navigation: {
          enabled: false,
        },
      },
    },
  };
}
