import { Component, Input, OnInit } from '@angular/core';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface IJsonLd {
  [key: string]: any;
}

@Component({
  selector: 'qsc-json-ld',
  standalone: true,
  imports: [],
  template: '<div [innerHTML]="html"></div>',
})
export class JsonLdComponent implements OnInit {
  @Input() schema?: IJsonLd;
  html?: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    if (this.schema) {
      this.html = this.getSafeHTML(this.schema);
    }
  }

  private getSafeHTML(jsonLD: IJsonLd): SafeHtml {
    const json = jsonLD
      ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>')
      : '';
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
