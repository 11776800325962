import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ButtonComponent } from '@shared/components';
import { FileHashPipe } from '@shared/pipes/file-hash/file-hash.pipe';
import { GoogleTagManagerService } from '@core/index';
import { ContentAnalytics } from '@models/analytics';

export enum MapActionModalEnum {
  Add,
  Remove,
  Review,
}

@Component({
  selector: 'qsc-map-action-modal',
  templateUrl: './map-action-modal.component.html',
  styleUrls: ['./map-action-modal.component.scss'],
  standalone: true,
  imports: [ButtonComponent, FileHashPipe, TranslateModule, CommonModule],
})
export class MapActionModalComponent {
  actionModalType: MapActionModalEnum = MapActionModalEnum.Add;
  gasStation = '';
  confirm = false;

  constructor(
    protected readonly bsModalRef: BsModalRef,
    private readonly googleTagManager: GoogleTagManagerService
  ) {}

  getTitle(): string {
    switch (this.actionModalType) {
      case MapActionModalEnum.Add:
        return '@VEHICLES-CONTENT-MAPS-MODAL-TITLE-4';
      case MapActionModalEnum.Remove:
        return '@VEHICLES-CONTENT-MAPS-MODAL-TITLE-5';
      case MapActionModalEnum.Review:
        return '@VEHICLES-CONTENT-MAPS-MODAL-TITLE-6';
    }
  }

  getSubtitle(): string {
    if (this.actionModalType === MapActionModalEnum.Review) {
      return '@VEHICLES-CONTENT-MAPS-MODAL-SUBTITLE-1';
    }
    return this.gasStation;
  }

  getButtonLabel(): string {
    switch (this.actionModalType) {
      case MapActionModalEnum.Add:
        return '@VEHICLES-CONTENT-MAPS-MODAL-BUTTON-1';
      case MapActionModalEnum.Remove:
        return '@VEHICLES-CONTENT-MAPS-MODAL-BUTTON-2';
      case MapActionModalEnum.Review:
        return '@VEHICLES-CONTENT-MAPS-MODAL-BUTTON-3';
    }
  }

  onButtonClick(): void {
    this.confirm = true;
    this.sendGtmEventOnButtonClick();
    this.bsModalRef.hide();
  }

  isAddAction(): boolean {
    return this.actionModalType === MapActionModalEnum.Add;
  }

  sendGtmEventOnButtonClick(): void {
    this.googleTagManager.sendCustomEvent(
      ContentAnalytics.category.encontrarPosto,
      this.actionModalType === MapActionModalEnum.Add
        ? ContentAnalytics.action.adicionarPosto
        : ContentAnalytics.action.removerPosto,
      this.gasStation
    );
  }
}
