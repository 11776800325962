import { Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ElementService } from '@shared/services';

export enum LegendIconEnum {
  GasStation = 'icon-local-gas-station',
  GpsLocation = 'icon-localizacao_gps',
}

export enum LegendColorEnum {
  Yellow = 'yellow',
  Green = 'green',
  Red = 'red',
}

export interface ILegendData {
  icon: LegendIconEnum;
  color: LegendColorEnum;
  labelKey: string;
}

export class LegendBuilder {
  private legend?: HTMLElement;
  private container?: HTMLElement;

  constructor(
    private elementService: ElementService,
    private renderer: Renderer2,
    private translateService: TranslateService
  ) {
    this.createLegend();
    this.createContainer();
  }

  private createLegend(): void {
    this.legend = this.renderer.createElement('div');
    this.renderer.addClass(this.legend, 'find-gas-station__legend');
  }

  private createContainer(): void {
    this.container = this.renderer.createElement('div');
    this.renderer.addClass(this.container, 'find-gas-station__legend-container');
    this.renderer.appendChild(this.legend, this.container);
  }

  private addLabel(data: ILegendData): LegendBuilder {
    const label = this.elementService.createLabel(
      data.icon,
      data.color,
      this.translateService.instant(data.labelKey)
    );
    this.renderer.appendChild(this.container, label);
    return this;
  }

  addHighFlowGasStationLabel(): LegendBuilder {
    return this.addLabel({
      icon: LegendIconEnum.GasStation,
      color: LegendColorEnum.Yellow,
      labelKey: '@VEHICLES-CONTENT-MAPS-LEGEND-LABEL-1',
    });
  }

  addCommonGasStationLabel(): LegendBuilder {
    return this.addLabel({
      icon: LegendIconEnum.GasStation,
      color: LegendColorEnum.Green,
      labelKey: '@VEHICLES-CONTENT-MAPS-LEGEND-LABEL-2',
    });
  }

  addOriginAndDestinationLabel(inArea = false): LegendBuilder {
    return this.addLabel({
      icon: LegendIconEnum.GpsLocation,
      color: LegendColorEnum.Red,
      labelKey: inArea ? '@VEHICLES-CONTENT-MAPS-LEGEND-LABEL-4' : '@VEHICLES-CONTENT-MAPS-LEGEND-LABEL-3',
    });
  }

  build(): HTMLElement {
    return this.legend!;
  }
}
