import { Component, Input, OnInit } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { Router, RouterLink } from '@angular/router';
import { GoogleTagManagerService } from '@core/index';
import { HomeAnalytics } from '@models/analytics';

export interface ICardMoreAboutData {
  title: string;
  description: string;
  iconClass: string;
  variant: string;
  toUrl: string;
}

@Component({
  selector: 'qsc-card-more-about',
  standalone: true,
  imports: [RouterLink, TranslateModule],
  templateUrl: './card-more-about.component.html',
  styleUrls: ['./card-more-about.component.scss'],
})
export class CardMoreAboutComponent implements OnInit {
  @Input() data: ICardMoreAboutData = {} as ICardMoreAboutData;
  modifierClass = '';

  constructor(
    private readonly router: Router,
    private readonly googleTagManager: GoogleTagManagerService
  ) {}

  ngOnInit(): void {
    this.modifierClass = this.getModifierClass();
  }

  getModifierClass() {
    return this.data?.variant ? `card-more-about--${this.data?.variant}` : '';
  }

  goToPage() {
    this.router.navigateByUrl(this.data.toUrl);
    this.sendGtmEvent();
  }

  sendGtmEvent() {
    this.googleTagManager.sendCustomEvent(
      HomeAnalytics.category.conhecaMais,
      this.data.toUrl.split('/')[1],
      HomeAnalytics.label.clique
    );
  }
}
