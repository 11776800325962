import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { IMarkerData } from '../marker/marker-data';

@Injectable({
  providedIn: 'root',
})
export class GooglePlacesService {
  private autocompleteService?: google.maps.places.AutocompleteService;
  private placesService?: google.maps.places.PlacesService;

  constructor(private readonly ngZone: NgZone) { }

  async initializeService() {
    const {
      AutocompleteService: AutocompleteService,
      PlacesService: PlacesService,
    } = (await google.maps.importLibrary(
      'places'
    )) as google.maps.PlacesLibrary;

    if (!this.autocompleteService) {
      this.autocompleteService = new AutocompleteService();
    }

    if (!this.placesService) {
      const map = new google.maps.Map(document.createElement('div'));
      this.placesService = new PlacesService(map);
    }
  }

  getPlacePredictions(
    input: string
  ): Observable<google.maps.places.AutocompletePrediction[]> {
    return new Observable((observer) => {
      if (!input.trim()) {
        observer.next([]);
        observer.complete();
        return;
      }

      this.autocompleteService?.getPlacePredictions(
        { input },
        (predictions, status) => {
          this.ngZone.run(() => {
            if (
              status === google.maps.places.PlacesServiceStatus.OK &&
              predictions
            ) {
              observer.next(predictions);
            } else {
              observer.next([]);
            }
            observer.complete();
          });
        }
      );
    });
  }

  async getPlaceId(data: IMarkerData): Promise<string | null> {
    return new Promise((resolve) => {
      const query = `${data.localName}, ${data.address}, ${data.area}, ${data.city}, ${data.latitude}, ${data.longitude}`;

      this.searchPlace(query).then(
        (value: google.maps.places.PlaceResult[]) => {
          const results: google.maps.places.PlaceResult[] = value;
          if (!results || results.length === 0) return;
          resolve(results[0]?.place_id ?? null);
        }
      );
    });
  }

  async searchPlace(query: string): Promise<google.maps.places.PlaceResult[]> {
    return new Promise((resolve) => {
      this.placesService?.textSearch({ query }, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          resolve(results);
        } else {
          resolve([]);
        }
      });
    });
  }

  async getPlaceDetails(
    placeId: string | null,
    fields: google.maps.places.PlaceDetailsRequest['fields']
  ): Promise<google.maps.places.PlaceResult> {
    return new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
      if (!placeId) {
        reject('ID do local não fornecido.');
        return;
      }

      this.placesService?.getDetails(
        {
          placeId,
          fields,
        },
        (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && place) {
            resolve(place);
          } else {
            reject(`Erro ao buscar detalhes do local: ${status}`);
          }
        }
      );
    });
  }
}
